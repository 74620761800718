import React, { useEffect, useState } from 'react'
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Button, IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery, Tooltip } from "@mui/material";
import background from "../../assets/background.png";
import { GoldCoin, SilverCoin } from "../Coins";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useBalance } from "../../contexts/BalanceContext";
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import logoImage from '../../assets/newlogomaybe2.png'; // Import the logo image
import accountIcon from "../../assets/account_icon.png";
import { Typography } from '@mui/material';
import { ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';
import { FaPlus } from 'react-icons/fa';
import { SlWallet } from 'react-icons/sl';
import bankpinfail from '../../assets/bankpinfail.mp3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { usePlayerCount } from '../../hooks/usePlayerCount';
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from 'react-router-dom'; // Update this import
import ChatIcon from '@mui/icons-material/Chat';
import { ChatPanel } from './ChatPanel';
import CloseIcon from '@mui/icons-material/Close'; // Add this import

// Add this styled component near the top of your file
const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        background: '#1a1a1a',
        borderRadius: '12px',
        border: '1px solid rgba(255, 215, 0, 0.1)',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
    },
    '& .MuiMenuItem-root': {
        '&:hover': {
            background: 'linear-gradient(145deg, rgba(255, 215, 0, 0.1) 0%, rgba(255, 215, 0, 0.05) 100%)',
        },
    },
}));

interface HeaderProps {
    walletModalOpen: boolean;
    setWalletModalOpen: (open: boolean) => void;
    handleWalletModalOpen: () => void;
    setIsLoginModalOpen: (open: boolean) => void;
    setIsRegisterModalOpen: (open: boolean) => void;
    isChatOpen: boolean;
    setIsChatOpen: (open: boolean) => void;
}

export default function Header({
    walletModalOpen,
    setWalletModalOpen,
    handleWalletModalOpen,
    setIsLoginModalOpen,
    setIsRegisterModalOpen,
    isChatOpen,
    setIsChatOpen
}: HeaderProps) {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [shake, setShake] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isPulsing, setIsPulsing] = useState(false);
    const [showGoldTip, setShowGoldTip] = useState(false);
    const [showSilverTip, setShowSilverTip] = useState(false);
    const theme = useTheme();
    const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md'));
    const coinSize = isMediumOrLarger ? "26px" : "22px";
    const balanceTextClass = isMediumOrLarger ? "text-[1.75rem]" : "text-[1.25rem]";
    const { goldBalance, silverBalance, balanceType, changeBalanceType, disableModifications, addSilverBalance, inPlay } = useBalance();
    const { signedIn, handleLogout } = useAuth();
    const playerCount = usePlayerCount();
    const navigate = useNavigate();
    const bankPinSound = new Audio(bankpinfail);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuAnchorClose = () => {
        setMenuAnchorEl(null);
    };
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleProfileClick = () => {
        handleMenuClose();
        navigate('/profile'); // Update this line to use navigate
    };

    const handleExchangeClick = () => {
        // Handle exchange click
        setWalletModalOpen(true);
        handleMenuClose();
    };

    const handleLogoutClick = async () => {
        await handleLogout();
        handleMenuClose();
        window.location.reload();
    };


    const handleSilverAdd = () => {
        if (silverBalance >= 10000) {
            bankPinSound.play();
            setShake(true);
            setTimeout(() => {
                setShake(false);
            }, 400);
            return
        }
        addSilverBalance();
    }
    useEffect(() => {
        const interval = setInterval(() => {
            setIsPulsing(prev => !prev);
        }, 1500); // Toggle every 1.5 seconds for a more subtle effect

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // Check if tips have been shown before
        const goldTipShown = localStorage.getItem('goldTipShown');
        const silverTipShown = localStorage.getItem('silverTipShown');

        // Show gold tip if balance is 0, type is gold, and hasn't been shown
        if (goldBalance === 0 && balanceType === 'gold' && !goldTipShown) {
            setShowGoldTip(true);
        }

        // Show silver tip if balance is 0, type is silver, and hasn't been shown
        if (silverBalance === 0 && balanceType === 'silver' && !silverTipShown) {
            setShowSilverTip(true);
        }
    }, [goldBalance, silverBalance, balanceType]);

    const handleGoldTipClose = () => {
        setShowGoldTip(false);
        localStorage.setItem('goldTipShown', 'true');
    };

    const handleSilverTipClose = () => {
        setShowSilverTip(false);
        localStorage.setItem('silverTipShown', 'true');
    };

    return (
        <>
            <div className="relative z-50 w-full">
                {/* Main header container - updated to radiate from center */}
                <div className="flex items-center h-16 w-full bg-[#1a1a1a] border-b border-[#ffd700]/10 shadow-lg backdrop-blur-sm">
                    {/* Logo section */}
                    <h1 className="flex-1 font-bold select-none flex items-center">
                        <Link to="/" className="cursor-pointer transition-transform hover:scale-105">
                            <img
                                src={logoImage}
                                className="h-auto w-auto object-contain ml-1 md:ml-4 filter drop-shadow-gold"
                                alt="Sand Casino Logo"
                                style={{
                                    padding: '2px 0',
                                    maxHeight: isMediumOrLarger ? '64px' : '32px',
                                    maxWidth: isMediumOrLarger ? '260px' : '120px',
                                }}
                            />
                        </Link>
                    </h1>

                    {/* Balance display section */}
                    {signedIn && (
                        <div className="flex items-center bg-[#1a1a1a] rounded-xl pl-1 md:pl-3 h-8 md:h-10 relative border border-[2px] border-[#efbf04] border-r-0 backdrop-blur-sm hover:border-[] transition-all">
                            {(goldBalance >= 0 || silverBalance >= 0) && (
                                <div className="flex items-center h-8 md:h-10 pt-1 cursor-pointer" onClick={(event) => { if (menuAnchorEl) handleMenuAnchorClose(); else setMenuAnchorEl(event.currentTarget) }}>
                                    <Tooltip
                                        open={showGoldTip && balanceType === 'gold' && goldBalance === 0}
                                        onClose={handleGoldTipClose}
                                        arrow
                                        placement="bottom"
                                    >
                                        <Tooltip
                                            open={showSilverTip && balanceType === 'silver' && silverBalance === 0}
                                            onClose={handleSilverTipClose}
                                            arrow
                                            placement="bottom"
                                        >
                                            <div className="flex items-center">
                                                <h3 className={`text-[#05f783] leading-none mr-0.5 md:mr-1 ${balanceTextClass} whitespace-nowrap overflow-hidden text-ellipsis max-w-[40px] md:max-w-none`}>
                                                    {balanceType === 'gold' ? inPlay ? 'In Play' : goldBalance : silverBalance}
                                                </h3>
                                                {balanceType === 'gold' ? (
                                                    <GoldCoin
                                                        additionalStyles="ml-0.5 md:ml-1 mb-1"
                                                        style={{
                                                            width: isMediumOrLarger ? coinSize : '18px',
                                                            height: isMediumOrLarger ? coinSize : '18px'
                                                        }}
                                                    />
                                                ) : (
                                                    <SilverCoin
                                                        additionalStyles="ml-0.5 md:ml-1 mb-1"
                                                        style={{
                                                            width: isMediumOrLarger ? coinSize : '18px',
                                                            height: isMediumOrLarger ? coinSize : '18px'
                                                        }}
                                                    />
                                                )}
                                                <button
                                                    aria-controls="balance-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleMenuClick}
                                                    className={`text-white mt-2 ${disableModifications ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                                    disabled={disableModifications}
                                                >
                                                    <ArrowDropDownIcon className="align-top" style={{ width: coinSize, height: coinSize, color: '#05f783' }} />
                                                </button>
                                                <StyledMenu
                                                    id="balance-menu"
                                                    anchorEl={menuAnchorEl}
                                                    keepMounted
                                                    open={Boolean(menuAnchorEl)}
                                                    onClose={handleMenuAnchorClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <MenuItem onClick={() => { changeBalanceType('gold'); handleMenuAnchorClose() }}>
                                                        <div className="flex items-center w-full justify-end">
                                                            <h3 className="flex-2 text-[#05f783] leading-none mr-1">
                                                                {inPlay ? 'In Play' : goldBalance}
                                                            </h3>
                                                            <GoldCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                                                        </div>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { changeBalanceType('silver'); handleMenuAnchorClose(); }}>
                                                        <div className="flex items-center w-full justify-end">
                                                            <h3 className="text-[#05f783] leading-none mr-1">
                                                                {silverBalance}
                                                            </h3>
                                                            <SilverCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                                                        </div>
                                                    </MenuItem>
                                                </StyledMenu>
                                            </div>
                                        </Tooltip>
                                    </Tooltip>
                                </div>
                            )}
                            {signedIn && (
                                balanceType === 'gold' ? (
                                    <button
                                        data-testid='play-button'
                                        onClick={handleWalletModalOpen}
                                        className="border-[#efbf04] border-2 w-10 rounded-md ml-1 h-8 md:h-10 flex items-center justify-center hover:bg-[#080808] hover:scale-105 transition-all"
                                    >
                                        <SlWallet style={{ color: '#efbf04', fontSize: isMediumOrLarger ? '20px' : '16px' }} />
                                    </button>
                                ) : (
                                    <div className="relative">
                                        <button
                                            data-testid='play-button'
                                            onClick={handleSilverAdd}
                                            disabled={disableModifications}
                                            className={`border-[#efbf04] border-2 w-10 rounded-md ml-1 h-8 md:h-10 flex items-center justify-center hover:bg-[#080808] hover:scale-105 transition-all ${shake ? "shake-animation" : ""} ${disableModifications ? "cursor-not-allowed" : ""}`}
                                            style={{
                                                position: 'relative',
                                                width: isMediumOrLarger ? '40px' : '32px',
                                                height: isMediumOrLarger ? '40px' : '32px'
                                            }}
                                        >
                                            <FaPlus style={{ color: '#efbf04', fontSize: isMediumOrLarger ? '20px' : '16px' }} />
                                        </button>
                                    </div>
                                )
                            )}
                        </div>
                    )}

                    {/* Right section - update background gradients */}
                    <div className="flex-1 flex justify-end items-center mr-2 md:mr-14 gap-2">
                        {/* Player count with updated styling */}
                        {playerCount > 0 && (
                            <div className="hidden md:block">
                                <div className="flex items-center rounded-full px-4 py-2 border-2 border-[#efbf04]">
                                    <div
                                        className="animate-pulse"
                                        style={{
                                            width: '12px',
                                            height: '12px',
                                            borderRadius: '50%',
                                            marginRight: '8px',
                                            background: 'linear-gradient(145deg, #ffd700 0%, #ffb300 100%)',
                                            boxShadow: '0 0 12px rgba(255, 215, 0, 0.3)',
                                        }}
                                    />
                                    <span className="text-[#ffd700] text-md font-medium">
                                        {playerCount.toLocaleString()} playing
                                    </span>
                                </div>
                            </div>
                        )}

                        {/* Auth buttons with updated styling */}
                        {signedIn ? (
                            <>
                                <IconButton
                                    onClick={handleMenuOpen}
                                    sx={{
                                        border: '2px solid #efbf04',
                                        padding: isMediumOrLarger ? '8px' : '6px',
                                    }}
                                >
                                    <img
                                        src={accountIcon}
                                        alt="Account"
                                        className="opacity-90 hover:opacity-100 transition-opacity"
                                        style={{
                                            width: isMediumOrLarger ? 24 : 20,
                                            height: isMediumOrLarger ? 24 : 20,
                                        }}
                                    />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    MenuListProps={{ "aria-labelledby": "basic-button" }}
                                >
                                    <MenuItem onClick={handleProfileClick}>
                                        <ListItemIcon>
                                            <AccountCircleIcon />
                                        </ListItemIcon>
                                        Profile
                                    </MenuItem>
                                    <MenuItem onClick={handleExchangeClick}>
                                        <ListItemIcon>
                                            <SwapHorizIcon />
                                        </ListItemIcon>
                                        Exchange
                                    </MenuItem>
                                    <MenuItem onClick={handleLogoutClick}>
                                        <ListItemIcon>
                                            <ExitToAppIcon />
                                        </ListItemIcon>
                                        Log Out
                                    </MenuItem>
                                </Menu>
                            </>

                        ) : (
                            <div className="flex items-center gap-2">
                                <Button
                                    variant="outlined"
                                    onClick={() => setIsLoginModalOpen(true)}
                                    sx={{
                                        border: '1px solid rgba(255, 215, 0, 0.3)',
                                        color: '#ffd700',
                                        '&:hover': {
                                            border: '1px solid rgba(255, 215, 0, 0.5)',
                                            background: 'linear-gradient(145deg, rgba(255, 215, 0, 0.1) 0%, rgba(255, 215, 0, 0.05) 100%)',
                                        },
                                    }}
                                >
                                    Login
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => setIsRegisterModalOpen(true)}
                                    sx={{
                                        background: 'linear-gradient(145deg, #efbf04 0%, #ffb300 100%)',
                                        color: '#000',
                                        '&:hover': {
                                            background: 'linear-gradient(145deg, #efbf04 0%, #ffc000 100%)',
                                        },
                                    }}
                                >
                                    Register
                                </Button>
                            </div>
                        )}

                        {/* Chat button with updated styling */}
                        <IconButton
                            onClick={() => setIsChatOpen(!isChatOpen)}
                            sx={{
                                background: isChatOpen
                                    ? 'linear-gradient(145deg, #efbf04 0%, #ffb300 100%)'
                                    : 'linear-gradient(145deg, #2d2d2d 0%, #1a1a1a 100%)',
                                border: '2px solid #efbf04',
                                '&:hover': {
                                    background: isChatOpen
                                        ? 'linear-gradient(145deg, #efbf04 0%, #ffc000 100%)'
                                        : 'linear-gradient(145deg, #333333 0%, #2d2d2d 100%)',
                                },
                            }}
                        >
                            <ChatIcon sx={{
                                color: isChatOpen ? '#000' : '#efbf04',
                                fontSize: isMediumOrLarger ? '24px' : '20px'
                            }} />
                        </IconButton>
                    </div>
                </div>
            </div>

            <ChatPanel
                isOpen={isChatOpen}
                onClose={() => setIsChatOpen(false)}
                style={{
                    position: 'fixed',
                    right: 0,
                    top: '64px',
                    height: 'calc(100vh - 64px)',
                    width: '400px',
                    transform: `translateX(${isChatOpen ? '0' : '100%'})`,
                    transition: 'transform 0.3s ease-in-out',
                    zIndex: theme.zIndex.drawer - 1,
                }}
            />
            {/* Add this new mobile close button */}
            {isChatOpen && (
                <IconButton
                    onClick={() => setIsChatOpen(false)}
                    sx={{
                        position: 'fixed',
                        right: '8px',
                        top: '72px',
                        backgroundColor: 'hsla(24, 12.82%, 35%, 100%)',
                        zIndex: theme.zIndex.drawer,
                        display: { md: 'none' }, // Only show on mobile
                        '&:hover': {
                            backgroundColor: 'hsla(204, 19.23%, 45%, 100%)',
                        },
                    }}
                >
                    <CloseIcon sx={{ color: 'white' }} />
                </IconButton>
            )}
        </>
    )
}
