import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Chip,
  IconButton,
  FormHelperText,
  Paper,
  InputAdornment,
  Slider,
  Divider
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useVendorStatus } from '../../hooks/useVendorStatus';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { useMisc } from '../../contexts/MiscContext';
import { GoldCoin } from '../Coins';

const initialPrizeDistribution = [
  { place: "first", percentage: 100 }
];

const CreateLeaderboardButton: React.FC = () => {
  const { isStreamer } = useVendorStatus();
  const { session } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  
  // Form state
  const [title, setTitle] = useState('');
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [endTime, setEndTime] = useState<Date | null>(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)); // Default 1 week from now
  const [prizePool, setPrizePool] = useState<number>(1000);
  const [prizeDistribution, setPrizeDistribution] = useState(initialPrizeDistribution);
  
  // Validation state
  const [titleError, setTitleError] = useState('');
  const [dateError, setDateError] = useState('');
  const [prizeError, setPrizeError] = useState('');
  const [distributionError, setDistributionError] = useState('');

  // Don't render if not a streamer
  if (!isStreamer) return null;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setTitle('');
    setStartTime(new Date());
    setEndTime(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000));
    setPrizePool(1000);
    setPrizeDistribution(initialPrizeDistribution);
    setTitleError('');
    setDateError('');
    setPrizeError('');
    setDistributionError('');
  };

  const validateForm = (): boolean => {
    let isValid = true;
    
    // Validate title
    if (!title.trim()) {
      setTitleError('Title is required');
      isValid = false;
    } else {
      setTitleError('');
    }
    
    // Validate dates
    if (!startTime || !endTime) {
      setDateError('Both start and end times are required');
      isValid = false;
    } else if (startTime >= endTime) {
      setDateError('End time must be after start time');
      isValid = false;
    } else {
      setDateError('');
    }
    
    // Validate prize pool
    if (prizePool <= 0) {
      setPrizeError('Prize pool must be greater than 0');
      isValid = false;
    } else {
      setPrizeError('');
    }
    
    // Validate prize distribution
    const totalPercentage = prizeDistribution.reduce((sum, item) => sum + item.percentage, 0);
    if (totalPercentage !== 100) {
      setDistributionError('Prize distribution must add up to 100%');
      isValid = false;
    } else {
      setDistributionError('');
    }
    
    return isValid;
  };

  const handleAddPrizePlace = () => {
    // Find the next place to add
    const places = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth", "tenth"];
    const usedPlaces = prizeDistribution.map(p => p.place);
    const nextPlace = places.find(p => !usedPlaces.includes(p));
    
    if (nextPlace && prizeDistribution.length < 10) {
      // Redistribute percentages
      const newDist = [...prizeDistribution];
      const newPercentage = Math.floor(100 / (newDist.length + 1));
      
      // Redistribute percentages equally
      newDist.forEach((item, index) => {
        if (index === 0) {
          // Give any remainder to the first place
          item.percentage = 100 - (newPercentage * newDist.length);
        } else {
          item.percentage = newPercentage;
        }
      });
      
      // Add the new place
      newDist.push({
        place: nextPlace,
        percentage: newPercentage
      });
      
      setPrizeDistribution(newDist);
    }
  };

  const handleRemovePrizePlace = (index: number) => {
    if (prizeDistribution.length > 1) {
      const newDist = prizeDistribution.filter((_, i) => i !== index);
      
      // Redistribute percentages
      const newPercentage = Math.floor(100 / newDist.length);
      newDist.forEach((item, i) => {
        if (i === 0) {
          // Give any remainder to the first place
          item.percentage = 100 - (newPercentage * (newDist.length - 1));
        } else {
          item.percentage = newPercentage;
        }
      });
      
      setPrizeDistribution(newDist);
    }
  };

  const handleSliderChange = (index: number) => (event: Event, newValue: number | number[]) => {
    const newPercentage = newValue as number;
    const newDist = [...prizeDistribution];
    
    // Calculate the difference
    const oldPercentage = newDist[index].percentage;
    const diff = newPercentage - oldPercentage;
    
    // Don't allow changes that would make other percentages negative
    const otherPlaces = newDist.filter((_, i) => i !== index);
    const totalOtherPercentage = otherPlaces.reduce((sum, item) => sum + item.percentage, 0);
    
    if (totalOtherPercentage - diff < 0) return;
    
    // Update the current place
    newDist[index].percentage = newPercentage;
    
    // Distribute the difference proportionally among other places
    if (diff !== 0) {
      const factor = (totalOtherPercentage - diff) / totalOtherPercentage;
      
      for (let i = 0; i < newDist.length; i++) {
        if (i !== index) {
          newDist[i].percentage = Math.round(newDist[i].percentage * factor);
        }
      }
      
      // Ensure the total is exactly 100%
      const newTotal = newDist.reduce((sum, item) => sum + item.percentage, 0);
      if (newTotal !== 100) {
        // Add/subtract the difference from first place that isn't the one being adjusted
        const adjustIndex = index === 0 ? 1 : 0;
        if (adjustIndex < newDist.length) {
          newDist[adjustIndex].percentage += (100 - newTotal);
        }
      }
    }
    
    setPrizeDistribution(newDist);
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    
    setLoading(true);
    
    try {
      // Convert prize distribution to the required JSON format
      const prizeDistributionObj = prizeDistribution.reduce((obj, item) => {
        obj[item.place] = item.percentage;
        return obj;
      }, {} as Record<string, number>);
      
      // Call the create_leaderboard function
      const { data, error } = await supabase.rpc('create_leaderboard', {
        p_title: title,
        p_status: 'active', // Start as inactive
        p_start_time: new Date(startTime).toISOString(),
        p_end_time: new Date(endTime).toISOString(),
        p_prize_pool: prizePool,
        p_prize_distribution: prizeDistributionObj,
        p_streamer_id: session?.user?.id
      });
      
      if (error) throw error;
      
      if (data.error) {
        throw new Error(data.error);
      }
      
      toast.success("Leaderboard created successfully!");
      handleClose();
    } catch (error) {
      console.error('Error creating leaderboard:', error);
      toast.error(`Failed to create leaderboard: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const getPlaceLabel = (place: string) => {
    return place.charAt(0).toUpperCase() + place.slice(1);
  };

  const getTotalPrize = (percentage: number) => {
    return (percentage / 100) * prizePool;
  };

  return (
    <>
      <Button 
        variant="contained" 
        onClick={handleOpen}
        startIcon={<AddIcon />}
        sx={{ 
          bgcolor: '#D4AF37', 
          color: 'black',
          '&:hover': {
            bgcolor: '#B4941F',
          }
        }}
      >
        Create Leaderboard
      </Button>
      
      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: '#121212',
            color: 'white',
            borderRadius: 2
          }
        }}
      >
        <DialogTitle sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
          Create New Leaderboard
        </DialogTitle>
        
        <DialogContent sx={{ mt: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Leaderboard Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                error={!!titleError}
                helperText={titleError}
                InputLabelProps={{ sx: { color: 'rgba(255,255,255,0.7)' } }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(255,255,255,0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D4AF37',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D4AF37',
                    }
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  }
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={(newValue) => setStartTime(newValue)}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(255,255,255,0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D4AF37',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D4AF37',
                      }
                    },
                    '& .MuiInputBase-input': {
                      color: 'white',
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="End Time"
                  value={endTime}
                  onChange={(newValue) => setEndTime(newValue)}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(255,255,255,0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D4AF37',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D4AF37',
                      }
                    },
                    '& .MuiInputBase-input': {
                      color: 'white',
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            
            {dateError && (
              <Grid item xs={12}>
                <FormHelperText error>{dateError}</FormHelperText>
              </Grid>
            )}
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Prize Pool"
                type="number"
                value={prizePool}
                onChange={(e) => setPrizePool(parseFloat(e.target.value))}
                error={!!prizeError}
                helperText={prizeError}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><GoldCoin /></InputAdornment>,
                }}
                InputLabelProps={{ sx: { color: 'rgba(255,255,255,0.7)' } }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(255,255,255,0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D4AF37',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D4AF37',
                    }
                  },
                  '& .MuiInputBase-input': {
                    color: 'white',
                  },
                  '& .MuiInputAdornment-root': {
                    color: 'rgba(255,255,255,0.7)',
                  }
                }}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
                Prize Distribution
              </Typography>
              
              <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>
                  Distribute the prize pool between places
                </Typography>
                
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddPrizePlace}
                  disabled={prizeDistribution.length >= 10}
                  sx={{ 
                    color: '#D4AF37',
                    borderColor: '#D4AF37',
                    '&:hover': {
                      borderColor: '#B4941F',
                    }
                  }}
                  variant="outlined"
                  size="small"
                >
                  Add Place
                </Button>
              </Box>
              
              {distributionError && (
                <FormHelperText error sx={{ mb: 2 }}>{distributionError}</FormHelperText>
              )}
              
              <Paper 
                elevation={3}
                sx={{ 
                  p: 2, 
                  bgcolor: 'rgba(0,0,0,0.3)',
                  borderRadius: 2
                }}
              >
                {prizeDistribution.map((prize, index) => (
                  <Box key={prize.place} sx={{ mb: index < prizeDistribution.length - 1 ? 2 : 0 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Typography sx={{ fontWeight: 500 }}>
                        {getPlaceLabel(prize.place)}
                      </Typography>
                      
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Chip 
                          label={`${prize.percentage}%`}
                          sx={{ 
                            bgcolor: '#D4AF37', 
                            color: 'black',
                            mr: 1,
                            fontWeight: 'bold'
                          }}
                        />
                        
                        <Chip 
                          label={`$${getTotalPrize(prize.percentage).toFixed(2)}`}
                          sx={{ 
                            bgcolor: 'rgba(255,255,255,0.1)', 
                            color: 'white',
                            mr: 1
                          }}
                        />
                        
                        {prizeDistribution.length > 1 && (
                          <IconButton 
                            size="small"
                            onClick={() => handleRemovePrizePlace(index)}
                            sx={{ color: 'rgba(255,0,0,0.7)' }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                    
                    <Slider
                      value={prize.percentage}
                      onChange={handleSliderChange(index)}
                      aria-labelledby={`prize-slider-${prize.place}`}
                      min={1}
                      max={99}
                      sx={{
                        color: '#D4AF37',
                        '& .MuiSlider-thumb': {
                          '&:hover, &.Mui-focusVisible': {
                            boxShadow: '0px 0px 0px 8px rgba(212, 175, 55, 0.16)',
                          },
                        },
                      }}
                    />
                    
                    {index < prizeDistribution.length - 1 && (
                      <Divider sx={{ mt: 2, bgcolor: 'rgba(255,255,255,0.1)' }} />
                    )}
                  </Box>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        
        <DialogActions sx={{ p: 2, borderTop: '1px solid rgba(255,255,255,0.1)' }}>
          <Button 
            onClick={handleClose}
            sx={{ color: 'rgba(255,255,255,0.7)' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            disabled={loading}
            sx={{ 
              bgcolor: '#D4AF37', 
              color: 'black',
              '&:hover': {
                bgcolor: '#B4941F',
              }
            }}
          >
            {loading ? 'Creating...' : 'Create Leaderboard'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateLeaderboardButton;