import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Button, 
  Paper, 
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Switch
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

// List of available currencies
const CURRENCIES = [
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'GBP', symbol: '£', name: 'British Pound' }
];

function SlotGameEmbed({ 
  gameId, 
  title, 
  onClose = null, 
  initialPlayMode = 'real', 
  hidePlayModeOptions = false,
  cacheGameData = null,
  cachedGameData = null,  // Add this prop to accept cached data
  onPlayModeChange = null,
  isFullscreen = false
}) {
  const { session, user } = useAuth();
  const { balance, setInPlay, fetchBalances } = useBalance();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gameUrl, setGameUrl] = useState(null);
  const [showModeSelection, setShowModeSelection] = useState(!hidePlayModeOptions);
  const [playMode, setPlayMode] = useState(initialPlayMode);
  const [currency, setCurrency] = useState('USD');
  const [gameSessions, setGameSessions] = useState({
    real: null,
    demo: null
  });
  const gameContainerRef = useRef(null);
  const iframeRef = useRef(null); // Add ref for the iframe
  const test = true;

  // Load both game sessions in the background
  useEffect(() => {
    if (gameId) {
      // Check if we have cached data first
      if (cachedGameData && cachedGameData.gameId === gameId && cachedGameData.mode === playMode && cachedGameData.currency === currency) {
        setGameUrl(cachedGameData.url);
        setGameSessions(prev => ({
          ...prev,
          [cachedGameData.mode]: cachedGameData.url
        }));
        setLoading(false);
      } else {
        // If no cached data available, create the sessions
        if (!gameSessions.demo) {
          createGameSession(gameId, 'demo', false);
        }
        
        if (!gameSessions.real && (user?.id || session?.user?.id)) {
          createGameSession(gameId, 'real', false);
        }
      }
    }
  }, [gameId, currency, user, session, cachedGameData, currency]);

  // Set active game URL when mode changes or sessions are loaded
  useEffect(() => {
    if (gameSessions[playMode]) {
      setGameUrl(gameSessions[playMode]);
      setLoading(false);
    }
  }, [playMode, gameSessions]);

  // Update effect to sync with parent component when initialPlayMode changes
  useEffect(() => {
    if (initialPlayMode !== playMode) {
      setPlayMode(initialPlayMode);
    }
  }, [initialPlayMode]);

  // Function to create a game session and get the URL
  async function createGameSession(gameId, mode = 'real', setActive = true, currency = 'USD') {
    if (mode === 'real' && !user?.id && !session?.user?.id) {
      if (setActive) {
        setError("You must be logged in to play in real money mode");
      }
      return;
    }
    
    if (setActive) {
      setLoading(true);
      setError(null);
    }
    
    try {
      const userId = user?.id || session?.user?.id;
      
      // Use the appropriate endpoint based on the play mode
      const endpoint = mode === 'real' ? 'sessions' : 'demo';
      
      // Build the request data
      let requestData;
      
      if (mode === 'real') {
        requestData = {
          casino_id: process.env.REACT_APP_CASINO_ID,
          game: gameId,
          currency: currency,
          locale: 'en',
          ip: '127.0.0.1',
          balance: balance/5,
          client_type: 'desktop',
          user: {
            id: userId,
            country: 'US'
          },
          urls: {
            return_url: window.location.href
          }
        };
      } else {
        requestData = {
          casino_id: process.env.REACT_APP_CASINO_ID,
          game: gameId,
          locale: 'en',
          ip: '127.0.0.1',
          client_type: 'desktop',
          urls: {
            return_url: window.location.href
          }
        };
      }
      
      // Generate signature for real money play only
      const signature = mode === 'real' 
        ? CryptoJS.HmacSHA256(
            JSON.stringify(requestData), 
            test ? process.env.REACT_APP_CASINO_TEST_AUTH_TOKEN : process.env.REACT_APP_CASINO_AUTH_TOKEN
          ).toString()
        : null;
      
      const baseUrl = test ? process.env.REACT_APP_CASINO_TEST_URL : process.env.REACT_APP_CASINO_URL;
      
      const response = await axios.post(
        `${baseUrl}/${endpoint}`, 
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
            ...(mode === 'real' ? { 'X-REQUEST-SIGN': signature } : {})
          }
        }
      );
      
      if (response.data?.launch_options?.game_url) {
        const url = response.data.launch_options.game_url;
        
        // Store the URL in the sessions object
        setGameSessions(prev => ({
          ...prev,
          [mode]: url
        }));
        
        // If this is the active mode, update the displayed URL
        if (setActive && mode === playMode) {
          setGameUrl(url);
          setLoading(false);
        }
        
        // Cache the game data
        if (cacheGameData) {
          cacheGameData({
            gameId,
            mode,
            url,
            currency
          });
        }
      } else {
        throw new Error("Failed to get game URL");
      }
    } catch (error) {
      console.error(`Error creating ${mode} game session:`, error);
      if (setActive) {
        setError(error.message || `Failed to create ${mode} game session`);
        setLoading(false);
      }
    }
  }

  const toggleFullscreen = () => {
    if (gameContainerRef.current) {
      if (onClose) {
        onClose('fullscreen');
      }
    }
  };

  const handlePlayModeChange = (mode) => {
    setPlayMode(mode);
    
    setShowModeSelection(false);
    
    
    // Notify parent component if callback exists
    if (onPlayModeChange) {
      onPlayModeChange(mode);
    }
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  // Only set iframe src once when the URL changes
  useEffect(() => {
    if (gameUrl && iframeRef.current) {
      // Only set src if it's different or iframe doesn't have a src
      if (!iframeRef.current.src || iframeRef.current.src !== gameUrl) {
        iframeRef.current.src = gameUrl;
      }
    }
  }, [gameUrl]);

  useEffect(() => {
    setInPlay(true);;
    return () => {
      fetchBalances();
      setInPlay(false);
    }
  }, []);

  

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: isFullscreen ? 0 : 2,
        bgcolor: '#121212',
        position: 'relative'
      }}
      ref={gameContainerRef}
    >
      {/* Header */}
      {/* <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        p: 1,
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        bgcolor: '#1A1A1A',
        width: '100%',
        zIndex: 10
      }}>
        <Typography variant="h6" sx={{ color: '#efbf04', fontWeight: 'bold' }}>
          {title || `Playing: ${gameId}`}
        </Typography>

      </Box>
       */}
      {/* Main content */}
      <Box sx={{ 
        position: 'relative',
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column',
        bgcolor: '#000',
        overflow: 'hidden',
      }}>
        {/* Game iframe - Use the ref to keep the iframe stable */}
        <Box sx={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          height: '100%', 
          width: '100%',
        }}>
          <iframe 
            ref={iframeRef}
            // Don't set src here, set it in the useEffect
            title={title || gameId}
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            style={{ 
              border: 'none',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
        
        {/* Play mode selection overlay */}
        {showModeSelection && (
          <Box sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 5,
          }}>
            {/* <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>
                Display Balance in
              </Typography>
              
              <Select
                value={currency}
                onChange={handleCurrencyChange}
                sx={{ 
                  bgcolor: '#2d2d2d', 
                  color: 'white',
                  width: 120,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.2)'
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: '#2d2d2d',
                      color: 'white'
                    }
                  }
                }}
              >
                {CURRENCIES.map(curr => (
                  <MenuItem key={curr.code} value={curr.code}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{ mr: 1 }}>{curr.symbol}</Typography>
                      {curr.code}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Box> */}
            
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button
                variant="contained"
                onClick={() => handlePlayModeChange('real')}
                sx={{
                  bgcolor: '#4caf50',
                  color: 'white',
                  '&:hover': {
                    bgcolor: '#388e3c'
                  },
                  px: 3
                }}
                startIcon={<PlayArrowIcon />}
              >
                Real Play
              </Button>
              
              <Button
                variant="contained"
                onClick={() => handlePlayModeChange('demo')}
                sx={{
                  bgcolor: '#424242',
                  color: 'white',
                  '&:hover': {
                    bgcolor: '#616161'
                  },
                  px: 3
                }}
                startIcon={<PlayArrowIcon />}
              >
                Fun Play
              </Button>
            </Box>
          </Box>
        )}
        
        {/* Loading overlay */}
        {loading && (
          <Box sx={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            right: 0, 
            bottom: 0, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 4
          }}>
            <CircularProgress size={60} sx={{ color: 'white' }} />
          </Box>
        )}
        
        {/* Error overlay */}
        {error && (
          <Box sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(0, 0, 0, 0.9)',
            zIndex: 6,
            p: 3
          }}>
            <Typography variant="h6" sx={{ color: 'error.main', mb: 2 }}>
              Error Loading Game
            </Typography>
            <Typography sx={{ color: 'white', mb: 3, textAlign: 'center' }}>
              {error}
            </Typography>
            <Button 
              variant="contained" 
              color="primary"
              onClick={() => createGameSession(gameId, playMode, true)}
            >
              Try Again
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  );
}

export default SlotGameEmbed; 