import React, { useState } from 'react';
import { Avatar, Button, Collapse, List, ListItem, ListItemText, Paper, Typography, Grid, IconButton, Box, Switch, FormControlLabel, LinearProgress } from '@mui/material';
import { CalendarToday, Casino, SportsEsports, Favorite, Email, VpnKey, History, Refresh } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import { useProfile } from '../../contexts/ProfileContext';
import { usePayments } from '../../hooks/useExchangeHistory';
import { GoldCoin } from '../Coins';
import LevelUpBar from '../LevelUpBar';
import ChangeEmailModal from './ChangeEmailModal';
import ChangePasswordModal from './ChangePasswordModal';
import LoadingScreen from '../LoadingScreen';

const ProfilePage: React.FC = () => {
    const { session } = useAuth();
    const { profile, verifyEmail, changePassword, changeEmail, toggleHideUsername, changeProfileAvatar, loading } = useProfile();
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [historyOpen, setHistoryOpen] = useState(false);
    const exchangeHistory = usePayments();

    if (loading) {
        return <LoadingScreen />;
    }

    const handleEmailSave = (currentEmail: string, newEmail: string) => {
        // Implement email change logic
        changeEmail(currentEmail, newEmail);
    };

    const handlePasswordSave = (currentPassword: string, newPassword: string) => {
        changePassword(currentPassword, newPassword);
    };

    // Remove this function as it's no longer needed:
    const handleProfilePictureUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Implement profile picture upload logic
    };

    // const exchangeHistory = [
    //     { date: '2023-01-01', details: 'Exchanged 100 tokens for $10' },
    //     { date: '2023-02-01', details: 'Exchanged 200 tokens for $20' },
    //     // Add more history items as needed
    // ];

    // Mock data for level and XP
    const currentLevel = 10;
    const currentXP = 200;
    const levelXP = {
        "1": 500, "2": 2000, "3": 5000, "4": 10000, "5": 20000,
        "6": 35000, "7": 55000, "8": 75000, "9": 100000, "10": 200000
    };

    const getProgress = () => {
        const prevLevelXP = levelXP[currentLevel as keyof typeof levelXP] || 0;
        const nextLevelXP = levelXP[(currentLevel + 1) as keyof typeof levelXP] || prevLevelXP;
        return ((currentXP - prevLevelXP) / (nextLevelXP - prevLevelXP)) * 100;
    };

    return (
        <Paper className="game-container" elevation={3} sx={{ background: 'radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%)', minHeight: '100vh', maxWidth: 'md', mx: 'auto', mt: 12, p: 4, borderRadius: 2 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ textAlign: 'center', mb: 3 }}>
                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                        <Avatar
                            src={profile.avatarUrl}
                            sx={{
                                width: 120,
                                height: 120,
                                fontSize: '3rem',
                                margin: '0 auto',
                                bgcolor: 'primary.main',
                                mb: 2
                            }}
                        >
                            {session?.user?.user_metadata?.username?.[0]?.toUpperCase()}
                        </Avatar>
                        <IconButton 
                            onClick={changeProfileAvatar}
                            sx={{
                                position: 'absolute',
                                bottom: 16,
                                right: -8,
                                bgcolor: 'secondary.main',
                                '&:hover': {
                                    bgcolor: 'secondary.dark',
                                },
                                width: 36,
                                height: 36
                            }}
                        >
                            <Refresh />
                        </IconButton>
                    </Box>
                    <Typography variant="h4" sx={{ mt: 2, fontWeight: 'bold' }}>
                        {session?.user?.user_metadata?.username}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {session?.user?.email}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" color="text.secondary" sx={{ mb: 3, fontWeight: 'bold' }}>Profile Information</Typography>
                    <Grid container spacing={1} sx={{ px: 3, pt: 3, pb: 2, borderRadius: 3, border: '2px solid #efbf04' }}>
                        {[
                            { label: 'Member since', value: profile.joinDate, icon: <CalendarToday /> },
                            { label: 'Tokens played', value: profile.totalPlayed, icon: <Casino /> },
                            { label: 'Games played', value: profile.totalPlays, icon: <SportsEsports /> },
                            { label: 'Favorite Game', value: profile.favoriteGame, icon: <Favorite /> },
                        ].map((item, index) => (
                            <Grid item xs={12} sm={12} key={index} sx={{ mb: 2 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {React.cloneElement(item.icon, { sx: { fontSize: '1.5rem' } })}
                                        <Typography variant="body1" sx={{ ml: 1, fontSize: '1.1rem' }}>{item.label}</Typography>
                                    </Box>
                                    <Typography variant="body1" fontWeight="bold" className={item.label === 'Tokens played' ? 'text-[#efbf04]' : ''} sx={{ fontSize: '1.1rem' }}>{item.label === 'Tokens played' && <GoldCoin style={{ height: '20px', width: '20px'}} />} {item.value}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* New Level-up Bar */}
                <LevelUpBar />

                <Grid item xs={12}>
                    <Typography variant="h6" color="text.secondary" sx={{ mb: 2, fontWeight: 'bold' }}>Account Settings</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" fullWidth onClick={() => setEmailModalOpen(true)} sx={{ bgcolor: 'secondary.main' }} startIcon={<Email />}>
                                Change Email
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" fullWidth onClick={() => setPasswordModalOpen(true)} sx={{ bgcolor: 'secondary.main' }} startIcon={<VpnKey />}>
                                Change Password
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" color="text.secondary" sx={{ mb: 2, fontWeight: 'bold' }}>Privacy Settings</Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={profile.hideUsername}
                                onChange={toggleHideUsername}
                                color="primary"
                            />
                        }
                        label="Hide username on leaderboards"
                    />
                </Grid>
            </Grid>
            <ChangeEmailModal
                open={emailModalOpen}
                onClose={() => setEmailModalOpen(false)}
                onSave={handleEmailSave}
            />
            <ChangePasswordModal
                open={passwordModalOpen}
                onClose={() => setPasswordModalOpen(false)}
                onSave={handlePasswordSave}
            />
        </Paper >
    );
};

export default ProfilePage;
