import React, { useEffect } from "react";
import { PiPokerChipFill } from "react-icons/pi";
import GenericWinningsComponent from "../GenericWinningsComponent";
import withFadeInDelay from "../withFadeInDelay";
import determineChipColor from "../../utils/determineChipColor";

const FadedInWinningsComponent = withFadeInDelay(GenericWinningsComponent, 500);
export default function SingleBet({
  bet,
  winnings,
  gameOver,
  name,
  margin,
  anteInfo,
  winState,
}: {
  bet: number;
  winnings: number;
  gameOver: boolean;
  name: string;
  margin?: string;
  anteInfo?: boolean;
  winState?: string;
}) {
  const [show, setShow] = React.useState(true);
  const [borderColor, setBorderColor] = React.useState("border-[#efbf04]");
  useEffect(() => {
    if (gameOver) {
      if (bet > 0) {
        if (winnings > 0) {
          setTimeout(() => {
            setShow(false);
          }, 300);
          setTimeout(() => {
            setShow(true);
          }, 3300);
        } else {
          setTimeout(() => {
            setBorderColor("border-red-600");
          }, 200);
          setTimeout(() => {
            setBorderColor("border-[#efbf04]");
          }, 3500);
        }
      }
    }
  }, [gameOver]);
  useEffect(() => {
    if (winState === "") {
      setBorderColor("border-[#efbf04]");
      setShow(true);
    }
  }, [winState]);

  return (
    <div
      id={name}
      className={`flex flex-col items-center justify-center ${margin} relative`}
    >
      <div
        className={`flex items-center justify-center ${borderColor} border-4 rounded-full w-10 lg:w-[5.3rem] h-10 lg:h-[5.3rem] relative`}
      >
        <div>
          {bet && show ? (
            <>
              <div className="flex align-center justify-center w-10 lg:w-[4.2rem] h-10 lg:h-[4.2rem] rounded-full relative opacity-90">
                <img
                  src={`/chips/chip${determineChipColor(bet)}.png`}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full shadow-lg"
                  alt={`${bet} chip`}
                />
              </div>
              {show && bet > 0 && (
                <p className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-md select-none ${determineChipColor(bet) === "25" ? "text-gray-100" : "text-gray-100"}`}>
                  {bet}
                </p>
              )}
            </>
          ) : null}
          {gameOver && (
            <FadedInWinningsComponent
              winnings={winnings}
              push={winnings === bet}
              gameOver={gameOver}
              name={name}
              shouldPlaySound={anteInfo}
              size={24}
            />
          )}
        </div>
      </div>
      <h1 className="text-[#efbf04] text-xl font-bold">{name}</h1>
    </div>
  );
}
