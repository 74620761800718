import yaml from 'js-yaml';

// Import the YAML file directly instead of using require
// This assumes your bundler (webpack/vite) is configured to handle YAML files
import yamlFilePath from '../sandcasino-int.yml';

// Fallback games if loading fails
const FALLBACK_GAMES = [
  {
    title: "Adventures",
    identifier: "Adventures",
    provider: "bgaming",
    category: "slots"
  },
  {
    title: "Alice WonderLuck",
    identifier: "AliceWonderLuck",
    provider: "bgaming",
    category: "slots"
  },
  {
    title: "Alien Fruits",
    identifier: "AlienFruits",
    provider: "bgaming",
    category: "slots"
  }
];

// Parse the YAML file once
let ALL_GAMES = [];
let SLOT_GAMES = [];
let TABLE_GAMES = [];
let LOADED = false;
let LOADING_ERROR = null;

// Function to load the data
async function loadGameData() {
  if (LOADED) return { ALL_GAMES, SLOT_GAMES, TABLE_GAMES };
  
  try {
    const response = await fetch(yamlFilePath);
    const yamlText = await response.text();
    const parsedData = yaml.load(yamlText);
    
    if (Array.isArray(parsedData)) {
      ALL_GAMES = parsedData;
      SLOT_GAMES = parsedData.filter(game => game.category === 'slots');
      TABLE_GAMES = parsedData.filter(game => game.category === 'table');
    } else {
      console.warn("YAML data is not an array:", parsedData);
      ALL_GAMES = FALLBACK_GAMES;
      SLOT_GAMES = FALLBACK_GAMES;
      TABLE_GAMES = [];
    }
  } catch (error) {
    console.error("Error fetching or parsing YAML:", error);
    LOADING_ERROR = "Failed to load games data";
    ALL_GAMES = FALLBACK_GAMES;
    SLOT_GAMES = FALLBACK_GAMES;
    TABLE_GAMES = [];
  } finally {
    LOADED = true;
  }
  
  return { ALL_GAMES, SLOT_GAMES, TABLE_GAMES };
}

// Start loading immediately
const dataPromise = loadGameData();

// Helper functions for filtering and finding games
function getGamesByCategory(category) {
  return ALL_GAMES.filter(game => game.category === category);
}

function findGameById(identifier) {
  return ALL_GAMES.find(game => game.identifier === identifier);
}

export {
  ALL_GAMES,
  SLOT_GAMES,
  TABLE_GAMES,
  LOADING_ERROR,
  LOADED,
  dataPromise,
  getGamesByCategory,
  findGameById
};