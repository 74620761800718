import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Container, 
  Button,
  CircularProgress,
  Paper,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  AppBar,
  Toolbar,
  Switch
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import SlotGameEmbed from './SlotGameEmbed';
import { findGameById, LOADED, dataPromise, LOADING_ERROR } from '../../utils/slotGames';

// List of available currencies
const CURRENCIES = [
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'GBP', symbol: '£', name: 'British Pound' }
];

// Standard aspect ratio for the game view
const GAME_ASPECT_RATIO = 1203 / 677;

function SlotPage() {
  const { gameId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const initialMode = searchParams.get('mode') || 'real';
  
  const [loading, setLoading] = useState(!LOADED);
  const [gameDetails, setGameDetails] = useState(null);
  const [error, setError] = useState(LOADING_ERROR);
  const [playMode, setPlayMode] = useState(initialMode);
  const [currency, setCurrency] = useState('USD');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isTheaterMode, setIsTheaterMode] = useState(false);
  const [hidePlayModeOptions, setHidePlayModeOptions] = useState(false);
  const [userMadeSelection, setUserMadeSelection] = useState(false);
  // Store the created game URL to prevent reload
  const [cachedGameData, setCachedGameData] = useState(null);
  const selectionTimeoutRef = useRef(null);
  const gameContainerRef = useRef(null);
  // Add a ref for the slot game embed component
  const slotGameEmbedRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Clear timeout when component unmounts
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      if (selectionTimeoutRef.current) {
        clearTimeout(selectionTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const loadGameData = async () => {
      if (!LOADED) {
        await dataPromise;
      }
      
      // Find game details in our list
      const game = findGameById(gameId);
      
      if (game) {
        setGameDetails(game);
        setLoading(false);
      } else {
        // If game isn't found
        setError("Game not found. Please select a different game.");
        setLoading(false);
      }

      // Consider URL parameter as an initial selection only if it's explicit
      if (searchParams.has('mode')) {
        // Mark as selected but don't hide the options yet
        // Let the user see what mode they're in first
        setUserMadeSelection(true);
      }
    };
    
    loadGameData();
  }, [gameId, searchParams]);

  // Effect to hide play mode options after selection
  useEffect(() => {
    // Clear any existing timeout
    if (selectionTimeoutRef.current) {
      clearTimeout(selectionTimeoutRef.current);
    }

    // Only set a timeout if user has made a selection
    if (userMadeSelection) {
      selectionTimeoutRef.current = setTimeout(() => {
        setHidePlayModeOptions(true);
      }, 3000); // Longer timeout to ensure user sees the selection
    } else {
      // If no selection made, make sure options are visible
      setHidePlayModeOptions(false);
    }
  }, [userMadeSelection, playMode]);

  const handleBackClick = () => {
    navigate('/slots');
  };

  const toggleFullscreen = () => {
    // Simplify to just toggle the state
    setIsFullscreen(!isFullscreen);
    
    // Exit theater mode when entering fullscreen
    if (isTheaterMode) setIsTheaterMode(false);
  };

  const toggleTheaterMode = () => {
    setIsTheaterMode(prev => !prev);
    // Exit fullscreen when entering theater mode
    if (isFullscreen) setIsFullscreen(false);
  };

  const handlePlayModeChange = (mode) => {
    setPlayMode(mode);
    
    // Explicitly mark that user has made a selection
    setUserMadeSelection(true);
    // Reset hide state to make sure options are visible after selection
    setHidePlayModeOptions(false);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  // Add this function to handle mode changes from the embed
  const handleEmbedPlayModeChange = (mode) => {
    // Update our own state when the embed changes mode
    setPlayMode(mode);
    setUserMadeSelection(true);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ 
        mt: 4, 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh'
      }}>
        <CircularProgress size={60} sx={{ color: '#D4AF37' }} />
        <Typography variant="h6" sx={{ mt: 2, color: 'white' }}>
          Loading game...
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, pb: 8 }}>
        <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleBackClick}
            sx={{ mr: 2 }}
          >
            Back to Games
          </Button>
        </Box>
        <Paper sx={{ p: 4, textAlign: 'center', bgcolor: 'background.paper' }}>
          <Typography variant="h6" color="error" gutterBottom>
            {error}
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleBackClick}
            sx={{ 
              mt: 2,
              backgroundColor: '#D4AF37', // Gold color
              color: '#000',
              '&:hover': {
                backgroundColor: '#B8860B', // Darker gold
              }
            }}
          >
            View All Games
          </Button>
        </Paper>
      </Container>
    );
  }

  // Use a unified approach instead of conditional rendering
  return (
    <Container
      maxWidth="2xl"
      sx={{
        mt: isFullscreen ? 0 : { xs: 2, md: 12 },
        mb: isFullscreen ? 0 : { xs: 2, md: 8 },
        px: isFullscreen ? 0 : { xs: 1, md: 1 },
        position: isFullscreen ? 'fixed' : 'static',
        top: isFullscreen ? 0 : 'auto',
        left: isFullscreen ? 0 : 'auto',
        right: isFullscreen ? 0 : 'auto',
        bottom: isFullscreen ? 0 : 'auto',
        width: isFullscreen ? '100vw' : 'auto',
        height: isFullscreen ? '100vh' : 'auto',
        bgcolor: isFullscreen ? '#000' : 'transparent',
        zIndex: isFullscreen ? 9999 : 'auto',
        padding: isFullscreen ? 0 : undefined,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transition: 'all 0.2s ease',
        minHeight: '100vh',
      }}
    >
      {gameDetails && !loading && !error && (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mb: isFullscreen ? 0 : 2,
              flexGrow: isFullscreen ? 1 : 0,
              height: isFullscreen ? '100%' : 'auto',
            }}
          >
            <Paper
              elevation={3}
              sx={{
                minWidth: '300px',
                minHeight: '300px',
                width: isFullscreen ? '100%' : (isTheaterMode ? '95%' : '80%'),
                maxWidth: isFullscreen ? '100%' : (isTheaterMode ? 'none' : '1203px'),
                height: isFullscreen ? '100%' : (isTheaterMode ? '80vh' : 'auto'),
                maxHeight: isFullscreen ? '100%' : (isTheaterMode ? 'none' : '677px'),
                aspectRatio: isFullscreen ? 'auto' : (isTheaterMode ? 'auto' : `${GAME_ASPECT_RATIO}`),
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#121212',
                overflow: 'hidden',
                borderRadius: isFullscreen ? 0 : 2,
                position: 'relative',
                transition: 'all 0.3s ease-in-out',
                border: isFullscreen ? 'none' : '2px solid #D4AF37', // Changed to gold color
                flexGrow: isFullscreen ? 1 : 0,
              }}
              ref={gameContainerRef}
            >
              <Box
                sx={{
                  position: 'relative',
                  flexGrow: 1,
                  overflow: 'hidden',
                  width: '100%',
                  height: '100%',
                }}
              >
                <SlotGameEmbed
                  ref={slotGameEmbedRef}
                  gameId={gameDetails.identifier}
                  title={gameDetails.title}
                  initialPlayMode={playMode}
                  hidePlayModeOptions={hidePlayModeOptions}
                  cacheGameData={setCachedGameData}
                  cachedGameData={cachedGameData}
                  onPlayModeChange={handleEmbedPlayModeChange}
                  isFullscreen={isFullscreen}
                />
              </Box>

              <AppBar position="static" color="transparent" sx={{ top: 'auto', bottom: 0, bgcolor: '#1A1A1A' }}>
                <Toolbar variant="dense" sx={{ justifyContent: 'space-between' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: 1,
                      px: 2,
                      py: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        color: playMode === 'demo' ? '#D4AF37' : 'rgba(255,255,255,0.5)', // Changed to gold
                        mr: 1,
                        transition: 'color 0.3s ease',
                      }}
                    >
                      Fun Play
                    </Typography>

                    <Switch
                      checked={playMode === 'real'}
                      onChange={() => {
                        const newMode = playMode === 'real' ? 'demo' : 'real';
                        handleEmbedPlayModeChange(newMode);
                      }}
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: '#D4AF37', // Changed to gold
                          '&:hover': {
                            backgroundColor: 'rgba(212, 175, 55, 0.08)',
                          },
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: '#D4AF37', // Changed to gold
                        },
                      }}
                      disabled={gameDetails.demo === false && playMode === 'real'}
                    />

                    <Typography
                      sx={{
                        color: playMode === 'real' ? '#D4AF37' : 'rgba(255,255,255,0.5)', // Changed to gold
                        ml: 1,
                        transition: 'color 0.3s ease',
                      }}
                    >
                      Real Play
                    </Typography>
                  </Box>

                  <Box>
                    {!isFullscreen && (
                      <IconButton
                        onClick={toggleTheaterMode}
                        sx={{ color: 'white', mr: 1 }}
                        title="Theater Mode"
                      >
                        <TheaterComedyIcon />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={toggleFullscreen}
                      sx={{ color: 'white' }}
                      title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
                    >
                      {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                  </Box>
                </Toolbar>
              </AppBar>
            </Paper>
          </Box>
        </>
      )}
    </Container>
  );
}

export default SlotPage; 