import React, { useState } from 'react';
import { FaAngleDoubleRight } from 'react-icons/fa';

interface SpeedControlProps {
    gameId: string;
    disableModification: boolean;
    additionalStyles?: string;
    speed: number;
    setSpeed: Function;
    maxSpeed?: number;
    showSpeed?: boolean;
}

const SpeedControl: React.FC<SpeedControlProps> = ({ gameId, disableModification, additionalStyles, speed, setSpeed, maxSpeed, showSpeed = true }) => {
    const toggleSpeed = () => {
        setSpeed((prevSpeed) => (prevSpeed % (maxSpeed || 3)) + 1);
    };

    return (
        <div className={`speed-control flex flex-col items-center ${additionalStyles}`}>
            {showSpeed && <span className="text-[#efbf04] text-lg font-bold mb-1">Speed</span>}
            <button 
                onClick={toggleSpeed} 
                disabled={disableModification} 
                className={`rs-button-small flex items-center px-2 ${disableModification ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                title="Adjust game speed"
            >
                <FaAngleDoubleRight className="mr-1 text-[#efbf04]" size={14} />
                <span className="text-[#efbf04] text-xs font-bold">{speed}x</span>
            </button>
        </div>
    );
};

export default SpeedControl;