import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient.js';
import { useAuth } from './AuthContext.js';
import { toast } from 'react-toastify';

const BalanceContext = createContext();

export const BalanceProvider = ({ children }) => {

  const { session, signedIn } = useAuth();
  const [MAXBET, setMAXBET] = useState(5000);
  const [goldBalance, setGoldBalance] = useState(null);
  const [silverBalance, setSilverBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [balanceType, setBalanceType] = useState('gold');
  const [referrer, setReferrer] = useState(null);
  const [disableModifications, setDisableModifications] = useState(false);
  const [inPlay, setInPlay] = useState(false);

  const fetchBalances = async () => {
    if (signedIn && session?.user?.id) {
      try {
        const { data, error } = await supabase
          .rpc('get_balances', { v_player_id: session?.user?.id });
        if (error) throw error;
  
        if (data) {
          setGoldBalance(data.balance);
          setSilverBalance(data.silverbalance);
        }
      } catch (error) {
        console.error('Error fetching balances:', error.message);
      }
    }
    setLoading(false);
  };
  

  useEffect(() => {
      fetchBalances();
  }, [signedIn, session]);

  const changeBalanceType = (type) => {
    if(disableModifications){
      return;
    }
    setBalanceType(type);
    setMAXBET(type === 'gold' ? 5000 : 10000);
  };
  const getActiveBalance = () =>{
    if(balanceType === 'gold'){
      return goldBalance;
    } else {
      return silverBalance;
    }
  }
  const addSilverBalance = async() =>{
    if(silverBalance>=10000) return;
    const { data, error } = await supabase.rpc('reset_player_balance', {
      v_user_id: session.user.id,
    });
    if(data === 15){
      toast.warn("You have 5 more balance resets left for today.");
    }
    if(error){
      toast.error(error.message);
    }
    await fetchBalances();
  }

  const value = {
    MAXBET,
    goldBalance,
    silverBalance,
    balanceType,
    loading,
    changeBalanceType,
    fetchBalances,
    getActiveBalance,
    disableModifications,
    setDisableModifications,
    addSilverBalance,
    referrer,
    setReferrer,
    inPlay,
    setInPlay,
  };

  return (
    <BalanceContext.Provider value={value}>
      {!loading && children}
    </BalanceContext.Provider>
  );
};

export const useBalance = () => {
  return useContext(BalanceContext);
};
