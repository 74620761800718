import React, { useEffect, useRef, useState } from "react";
import { Hand } from "../../objects/Ihand";
import CardComponent from "./CardComponent";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../GameBoard.css";
import { useVolume } from "../../contexts/VolumeContext"; // Import useVolume hook
import carddeal from "../../assets/carddeal.mp3";

interface HandComponentProps {
  name: string; // 'dealer' or 'player'
  hand?: Hand;
  dealerHand?: Hand;
  active_hand_index?: number;
  handsLength?: number;
  onDealerFinishing?: () => void;
  dealersTurn?: boolean;
  cardImages: any;
  freeBet?: boolean;
  soft?: boolean;
  speed?: number;
}

const HandComponent: React.FC<HandComponentProps> = ({
  name,
  hand,
  dealerHand,
  onDealerFinishing,
  active_hand_index,
  handsLength,
  dealersTurn,
  cardImages,
  freeBet,
  soft,
  speed
}) => {
  const audio = useRef(new Audio(carddeal)); // Use useRef to create the audio element
  const { volumes, mutedStates, activeGame } = useVolume(); // Use the useVolume hook to get volumes
  const [displayedCards, setDisplayedCards] = useState<number>(0);
  const [dealerDisplayedCards, setDealerDisplayedCards] = useState<number>(0);
  const [cardAnimations, setCardAnimations] = useState<string[]>([]);
  const [restored, setRestored] = useState<boolean>(false);
  const [isFlipped, setIsFlipped] = useState<{ [key: string]: boolean }>({});
  let currentIndex = useRef<number>(0);
  let currentLength = useRef<number>(1);
  let delay = speed ? 500 / speed : 500;

  useEffect(() => {
    setCardAnimations(new Array(2).fill("card-slide"));
  }, []);


  useEffect(() => {
    if (dealersTurn && name === "dealer") {
      const updatedAnimations = [...cardAnimations];
      updatedAnimations[1] = "card-flip"; // updating for the dealer's first card
      setCardAnimations(updatedAnimations);
    }
  }, [dealersTurn, name]);

  useEffect(() => {
    if (name === "player" &&
      active_hand_index !== undefined &&
      handsLength &&
      handsLength > 1) {
      setDisplayedCards(1);
      setCardAnimations([]);
      setIsFlipped(prev => ({
        [`${name}-0`]: true
      }));
      setRestored(false);
    }
  }, [active_hand_index, name, handsLength]);

  useEffect(() => {
    if (hand?.winstate !== "") {
      return;
    }
    let currentDisplay = displayedCards;

    if (
      (active_hand_index && active_hand_index > currentIndex.current) ||
      (handsLength && handsLength > currentLength.current)
    ) {
      if (handsLength && handsLength > 1) {
        setDisplayedCards(1);
        currentDisplay = 1;
        setCardAnimations(prev => {
          const newAnimations = [];
          newAnimations[0] = "card-flip";
          newAnimations[1] = "card-slide";
          return newAnimations;
        });
        setIsFlipped({
          [`${name}-0`]: true,

        });
      }
      currentIndex.current = active_hand_index ? active_hand_index : currentIndex.current;
      currentLength.current = handsLength ? handsLength : currentLength.current;
    }

    if (name === "player" && hand) {
      if (hand.cards.length > currentDisplay) {
        if (hand.cards.length > 2 && !restored) {
          setDisplayedCards(hand.cards.length);
          audio.current.volume = mutedStates[activeGame] ? 0 : volumes[activeGame] || 1;
          audio.current.play();
          for (let idx = currentDisplay; idx < hand.cards.length; idx++) {
            setTimeout(() => {
              setCardAnimations(prev => {
                const newAnimations = [...prev];
                newAnimations[idx] = "card-flip";
                return newAnimations;
              });
              setIsFlipped(prev => ({
                ...prev,
                [`${name}-${idx}`]: true
              }));
              }, 300);
            }
          setRestored(true);
        } else {
          setRestored(true);
          for (let idx = currentDisplay; idx < hand.cards.length; idx++) {
            setTimeout(() => {
              setDisplayedCards(idx + 1);
              audio.current.volume = mutedStates[activeGame] ? 0 : volumes[activeGame] || 1;
              audio.current.play();

              setTimeout(() => {
                setCardAnimations(prev => {
                  const newAnimations = [...prev];
                  newAnimations[idx] = "card-flip";
                  return newAnimations;
                });
                setIsFlipped(prev => ({
                  ...prev,
                  [`${name}-${idx}`]: true
                }));
              }, 300);
            }, idx < 2 ? idx * delay : 300);
          }
        }
      }
    }
  }, [hand, active_hand_index, handsLength, volumes]);

  useEffect(() => {
    if (name === "dealer" && dealerHand) {
      if (dealerHand.cards.length > dealerDisplayedCards) {
        for (let idx = dealerDisplayedCards; idx < dealerHand.cards.length; idx++) {
          setTimeout(() => {
            setDealerDisplayedCards(idx + 1);
            if (idx < 2) {
              if (idx !== 1) { // Only for first card
                setTimeout(() => {
                  setCardAnimations(prev => {
                    const newAnimations = [...prev];
                    newAnimations[idx] = "card-flip";
                    return newAnimations;
                  });
                  setIsFlipped(prev => ({
                    ...prev,
                    [`${name}-${idx}`]: true
                  }));
                }, 300);
              } else { // For second card
                setIsFlipped(prev => ({
                  ...prev,
                  [`${name}-${idx}`]: true // Set second card flipped by default
                }));
              }
            } else {
              audio.current.volume = mutedStates[activeGame] ? 0 : volumes[activeGame] || 1;
              audio.current.play();
              setTimeout(() => {
                setCardAnimations(prev => {
                  const newAnimations = [...prev];
                  newAnimations[idx] = "card-flip";
                  return newAnimations;
                });
                setIsFlipped(prev => ({
                  ...prev,
                  [`${name}-${idx}`]: true
                }));
              }, 300);
            }
          }, idx * delay);
        }
      }
    }
  }, [dealerHand, volumes]);

  // Add effect to handle dealer's turn flip
  useEffect(() => {
    if (dealersTurn && name === "dealer") {
      setTimeout(() => {
        setIsFlipped(prev => ({
          ...prev,
          [`${name}-1`]: true // Flip the second card when it's dealer's turn
        }));
      }, 300);
    }
  }, [dealersTurn, name]);

  useEffect(() => {
    if (
      dealerHand &&
      dealerHand.cards.length === dealerDisplayedCards &&
      name === "dealer" &&
      dealersTurn
    ) {
      if (onDealerFinishing) {
        setTimeout(() => {
          onDealerFinishing();
        }, delay);
      }
    }
  }, [dealerDisplayedCards, dealerHand, dealersTurn]);

  const currentHand = name === "dealer" ? dealerHand : hand;
  return (
    <TransitionGroup component={null}>
      {currentHand?.cards.map((card, index) => {
        const animationClass = cardAnimations[index] || "card-slide";
        let displayed =
          name === "dealer" ? dealerDisplayedCards : displayedCards;

        // Conditional check for rendering
        if (index + 1 > displayed) return null;
        return (
          <CSSTransition
            unmountOnExit
            key={`${name}${index}${animationClass}`}
            timeout={5000}
            classNames={animationClass}
          >
            <CardComponent
              key={`${name}${index}${Math.random()}${active_hand_index ? active_hand_index : "null"
                }}`}
              card={isFlipped[`${name}-${index}`] ? card : { suit: 'back', value: 'back' }}
              lastCard={index === currentHand.cards.length - 1}
              showCount={isFlipped[`${name}-${index}`]}
              count={currentHand.count}
              active={true}
              cardImages={cardImages}
              freeBet={freeBet}
              soft={soft}
            />
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};

export default HandComponent;