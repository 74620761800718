import React from "react";
import determineChipColor from "../utils/determineChipColor";

interface GeneralChipProps {
  amount: number;
  additionalStyles?: string;
}

export default function GeneralChip({ amount, additionalStyles = "" }: GeneralChipProps) {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className={`flex align-center justify-center w-6 lg:w-10 h-6 lg:h-10 rounded-full relative ${additionalStyles}`}>
        <img
          src={`/chips/chip${determineChipColor(amount)}.png`}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full shadow-lg"
          alt={`${amount} chip`}
        />
      </div>

      <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold text-sm select-none">
        {amount}
      </p>
    </div>
  );
}
