import React, { useState, useEffect, useRef } from "react";
import { 
  Box, Typography, Grid, Card, CardContent, CardMedia, Button, 
  Container, CircularProgress, CardActions, IconButton,
  Chip, Tooltip, Badge, Dialog, Paper, Stack
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import yaml from 'js-yaml';
import { 
  SLOT_GAMES, 
  LOADED, 
  LOADING_ERROR, 
  dataPromise 
} from '../../utils/slotGames';

function SlotGames() {
  const [loading, setLoading] = useState(!LOADED);
  const [error, setError] = useState(LOADING_ERROR);
  const [games, setGames] = useState(LOADED ? SLOT_GAMES : []);
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (!LOADED) {
      dataPromise.then(() => {
        setGames(SLOT_GAMES);
        setLoading(false);
        if (LOADING_ERROR) {
          setError(LOADING_ERROR);
        }
      });
    }
  }, []);

  // Function to handle clicking a game
  const handleGameClick = (game) => {
    navigate(`/slot/${game.identifier}?mode=real`);
  };

  // View all slots
  const handleViewAll = () => {
    navigate('/slots');
  };

  // Scroll functions
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -600, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 600, behavior: 'smooth' });
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 1, mb: 8 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'end', 
        alignItems: 'center',
        mb: 2
      }}>
        
        
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton 
            onClick={scrollLeft}
            sx={{ 
              color: '#efbf04',
              '&:hover': {
                backgroundColor: '#1a1a1a',
              }
            }}
          >
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
          
          <IconButton 
            onClick={scrollRight}
            sx={{ 
              color: '#efbf04',
              '&:hover': {
                backgroundColor: '#1a1a1a',
              }
            }}
          >
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
          
          <Button 
            variant="contained"
            onClick={handleViewAll}
            sx={{
              ml: 1,
              backgroundColor: '#efbf04', // Gold color
              color: '#000',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#B8860B', // Darker gold
              }
            }}
          >
            View All
          </Button>
        </Stack>
      </Box>
      
      {error && (
        <Box sx={{ 
          bgcolor: 'error.main', 
          color: 'white', 
          p: 2, 
          mb: 3, 
          borderRadius: 1,
          textAlign: 'center' 
        }}>
          {error}
        </Box>
      )}
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress sx={{ color: '#D4AF37' }} /> {/* Gold color */}
        </Box>
      ) : (
        <Box
          ref={scrollContainerRef}
          sx={{
            width: '100%',
            overflowX: 'auto',
            scrollbarWidth: 'none', // Hide scrollbar for Firefox
            '&::-webkit-scrollbar': {
              display: 'none', // Hide scrollbar for Chrome/Safari/Edge
            },
            pb: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              pb: 1,
              width: 'max-content', // Ensures the container expands to fit all items
            }}
          >
            {games.map((game) => (
              <Paper
                key={game.identifier}
                onClick={() => handleGameClick(game)}
                sx={{
                  width: 200,
                  height: 265,
                  backgroundImage: `url(https://cdn.softswiss.net/i/s6/softswiss/${game.image ? game.image : game.identifier}.webp)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: 2,
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)'
                  },
                  position: 'relative',
                  // Fallback background for failed image loads
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '40px',
                    background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
                    borderBottomLeftRadius: 2,
                    borderBottomRightRadius: 2,
                    zIndex: 1,
                  }
                }}
              >
              </Paper>
            ))}
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default SlotGames; 