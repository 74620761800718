import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Grid, 
  Card, 
  CardMedia, 
  CardContent,
  TextField,
  InputAdornment,
  CircularProgress,
  Paper
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { 
  SLOT_GAMES, 
  LOADED, 
  LOADING_ERROR, 
  dataPromise 
} from '../../utils/slotGames';

function SlotsList() {
  const [loading, setLoading] = useState(!LOADED);
  const [games, setGames] = useState(LOADED ? SLOT_GAMES : []);
  const [error, setError] = useState(LOADING_ERROR);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Load games from centralized data source
  useEffect(() => {
    if (!LOADED) {
      dataPromise.then(() => {
        setGames(SLOT_GAMES);
        setLoading(false);
        if (LOADING_ERROR) {
          setError(LOADING_ERROR);
        }
      });
    }
  }, []);

  // Filter games based on search term
  const filteredGames = games.filter(game => 
    game.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleGameClick = (game) => {
    navigate(`/slot/${game.identifier}?mode=real`);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Container className='min-h-screen' maxWidth="xl" sx={{ mt: 8, pb: 8 }}>
      <Typography variant="h3" component="h1" sx={{ 
        color: '#efbf04', 
        fontWeight: 'bold', 
        mb: 3,
        textAlign: 'center'
      }}>
        Slots
      </Typography>

      <Box sx={{ mb: 4 }}>
        <TextField
          fullWidth
          placeholder="Search slots..."
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'white' }}/>
              </InputAdornment>
            ),
            sx: {
              color: 'white',
              borderColor: '#D4AF37',
              borderRadius: 4,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(212, 175, 55, 0.5)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D4AF37',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#D4AF37',
              }
            }
          }}
          sx={{
            '& .MuiInputLabel-root': {
              color: 'white',
            },
            '& .MuiInputBase-input': {
              color: 'white',
            }
          }}
        />
      </Box>

      {error && (
        <Box sx={{ 
          bgcolor: 'error.main', 
          color: 'white', 
          p: 2, 
          mb: 3, 
          borderRadius: 1,
          textAlign: 'center' 
        }}>
          {error}
        </Box>
      )}
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress sx={{ color: '#D4AF37' }} />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {filteredGames.map((game) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={game.identifier}>
              <Paper
                key={game.identifier}
                onClick={() => handleGameClick(game)}
                sx={{
                  width: 220,
                  height: 292,
                  backgroundImage: `url(https://cdn.softswiss.net/i/s6/softswiss/${game.image ? game.image : game.identifier}.webp)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: 2,
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 0 10px 2px rgba(212, 175, 55, 0.5)', // Gold glow on hover
                  },
                  position: 'relative',
                  // Fallback background for failed image loads
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '40px',
                    background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
                    borderBottomLeftRadius: 2,
                    borderBottomRightRadius: 2,
                    zIndex: 1,
                  }
                }}
              >
              </Paper>
            </Grid>
          ))}
          
          {filteredGames.length === 0 && !loading && (
            <Box sx={{ 
              p: 4, 
              width: '100%', 
              textAlign: 'center',
              color: 'white'
            }}>
              <Typography variant="h6">
                No slots found matching "{searchTerm}"
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </Container>
  );
}

export default SlotsList; 