import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { AuthProvider, useAuth } from "../../contexts/AuthContext";
import logo from '../../assets/newlogomaybe2.png';
import PeopleIcon from '@mui/icons-material/People';
import { useMediaQuery, useTheme, Typography, Box, Button, Divider } from '@mui/material';
import RecentGamesTable from '../RecentGamesDisplay/RecentGamesTable';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CasinoIcon from '@mui/icons-material/Casino';
import { keyframes } from '@emotion/react';
import LoginModal from "../LoginModal";
import background from '../../assets/image_2.webp';
import SlotGames from "./HomePageSlots";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const CARD_DIMENSIONS = {
  width: {
    xs: 160,
    sm: 180,
    md: 220,
  },
  height: {
    xs: 212,
    sm: 239,
    md: 292,
  },
};

const EventBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { session } = useAuth();
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  const handleButtonClick = () => {
    // if (session) {
    //   // Scroll to silver leaderboards
    //   const leaderboardElement = document.getElementById('silver-leaderboard');
    //   if (leaderboardElement) {
    //     leaderboardElement.scrollIntoView({ behavior: 'smooth' });
    //   }
    // } else {
    //   // Open login modal
    //   setLoginModalOpen(true);
    // }
    window.open('https://discord.gg/QMNgDJVAuX', '_blank');
  };

  const handleCloseLoginModal = () => {
    setLoginModalOpen(false);
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          background: `
                       linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
                       url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 75%',
          backgroundRepeat: 'no-repeat',
          borderRadius: '12px',
          color: 'hsla(39, 6.41%, 90%, 100%)',
          padding: isMobile ? '15px' : '20px 30px',
          position: 'relative',
          overflow: 'hidden',
          animation: `${fadeIn} 0.5s ease-out`,
          marginTop: isMobile ? '0px' : '24px'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <EmojiEventsIcon sx={{ fontSize: isMobile ? 30 : 40, marginRight: '15px', color: 'hsl(156, 80%, 75%)' }} />
          <Typography
            variant={isMobile ? "h6" : "h5"}
            component="div"
            sx={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
            }}
          >
            Join Our Discord!
          </Typography>
        </Box>
        <Typography
          variant={isMobile ? "body2" : "body1"}
          sx={{
            marginBottom: '10px',
            fontSize: isMobile ? '1rem' : '1.2rem',
            fontWeight: 'bold'
          }}
        >
          Join our Discord for additional deposit and withdrawal options as well as free weekly poker tournaments for balance!
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={handleButtonClick}
            component={session ? Link : 'button'}
            to={session ? "/rewards" : undefined}
            sx={{
              backgroundColor: 'hsl(156, 80%, 75%)',
              color: 'hsla(24, 12.82%, 15%, 1)',
              '&:hover': {
                backgroundColor: 'hsl(156, 80%, 65%)',
              },
            }}
          >
            Join Discord
          </Button>
        </Box>
      </Paper>
      <LoginModal open={loginModalOpen} handleClose={handleCloseLoginModal} />
    </>
  );
};

function getRandomPlayerCount(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const SlotGameSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ mt: 2, mb: 4 }}>
      <Typography variant="h4" component="h2" sx={{ color: '#efbf04', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px' }}>
        Slots
      </Typography>

      <Divider sx={{ mb: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />

      <SlotGames />
    </Box>
  );
};

export default function SpacingGrid() {
  const [spacing, setSpacing] = React.useState(2);
  const [playerCounts, setPlayerCounts] = React.useState({
    blackjack: getRandomPlayerCount(22, 25),
    ultimateTexas: getRandomPlayerCount(17, 20),
    roulette: getRandomPlayerCount(16, 21),
    dice: getRandomPlayerCount(12, 15),
    plinko: getRandomPlayerCount(25, 31),
    duelArena: getRandomPlayerCount(11, 19),
    flowerPoker: getRandomPlayerCount(12, 15),
  });
  const { session, signedIn, loading } = useAuth();

  const theme = useTheme();
  const isMdOrSmaller = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px) and (orientation: portrait), (max-height:${theme.breakpoints.values.md}px) and (orientation: landscape)`);

  return (
    <div className="" style={{ minHeight: '100vh' }}>
      <img
        src={logo}
        alt="Logo"
        className="moving-logo"
        style={{
          position: "absolute",
          scale: '2',
          top: "50%",
          left: "5%",
          transform: "translate(-50%, -50%)",
          zIndex: -1,
          opacity: 0.2
        }}
        draggable="false"
      />
      <EventBanner />
      <Typography variant="h4" component="h2" sx={{ color: '#efbf04', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px', marginTop: '16px' }}>
        Originals
      </Typography>

      <Divider sx={{ mb: 1, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
      <Grid
        className="md:p-10"
        sx={{
          flexGrow: 1,
          gap: { xs: 1, sm: 2, md: 3 },
          padding: { xs: 3, sm: 4, md: 5 },
          margin: '10px auto',
        }}
        container
        justifyContent="center"
      >
        <Grid key="blackjack-logo" item>
          <Link to="/blackjack">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/blackjack.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.blackjack} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="ultimatetexas-logo" item>
          <Link to="/ultimate-texas">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/texas_holdem.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.ultimateTexas} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>

        <Grid key="roulette-logo" item>
          <Link to="/roulette">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/roulette.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.roulette} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="dice-logo" item>
          <Link to="/dice">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/dice.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.dice} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="mines-logo" item>
          <Link to="/mines">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/mines.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.flowerPoker} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="palmtower-logo" item>
          <Link to="/palmtower">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/palm_tower.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.dice} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="plinko-logo" item>
          <Link to="/plinko">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/plinko.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.dice} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="keno-logo" item>
          <Link to="/keno">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/keno.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
            </Paper>
          </Link>
        </Grid>
        <Grid key="flowerpoker-logo" item>
          <Link to="/flower-poker">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/flower_poker.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.flowerPoker} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>
        <Grid key="dharoks-logo" item>
          <Link to="/dharoks">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/dharoks_deathmatch.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.duelArena} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>

        <Grid key="duelarena-logo" item>
          <Link to="/duel-arena">
            <Paper
              className="game-card"
              sx={{
                width: CARD_DIMENSIONS.width,
                height: CARD_DIMENSIONS.height,
                backgroundImage: 'url("/newlogos/duel_arena.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              {/* <div className="player-count">
                <PeopleIcon style={{ marginRight: 5, color: 'hsla(156, 41.3%, 59.7%, 100%)' }} />
                {playerCounts.duelArena} {!isMdOrSmaller && 'playing'}
              </div> */}
            </Paper>
          </Link>
        </Grid>

      </Grid>
      <SlotGameSection />
      {session && loading === false && (
        <div style={{ margin: '20px' }}>
          <RecentGamesTable />
        </div>
      )}
    </div>
  );
}