import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient.js';
import { useAuth } from './AuthContext.js';
import { useVendorStatus } from '../hooks/useVendorStatus.tsx';

const MiscContext = createContext();

export const MiscProvider = ({ children }) => {
    const { session } = useAuth();
    const { isStreamer } = useVendorStatus();
    const [userList, setUserList] = useState([]);
    const [referrer, setReferrer] = useState(null);
    const [leaderboard, setLeaderboard] = useState([]);

    const fetchLeaderboard = async (referrer) => {
        console.log(isStreamer ? session?.user?.user_metadata?.username : referrer);
        const {data, error} = await supabase.rpc('get_leaderboard', {
            v_username: isStreamer ? session?.user?.user_metadata?.username : referrer
        });
        if (data) setLeaderboard(data);
    };

    const fetchReferrer = async () => {
        const { data, error } = await supabase
          .from('players')
          .select('referrer')
          .eq('uuid', session?.user?.id)
          .single();
        if (data) setReferrer(data.referrer);
    };

    const fetchUserList = async () => {
        const { data, error } = await supabase
            .rpc('get_user_list');
        if (data) setUserList(data);
    };

    useEffect(() => {
        fetchUserList();
    }, []);
    useEffect(() => {
        fetchReferrer();
    }, [session]);
    useEffect(() => {
        if (referrer || isStreamer) {
            fetchLeaderboard(referrer);
        }
    }, [referrer,isStreamer]);

    return (
        <MiscContext.Provider value={{ userList, referrer, leaderboard }}>
            {children}
        </MiscContext.Provider>
    )
}

export const useMisc = () => {
    return useContext(MiscContext);
}
