import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Avatar, Container, Grid, Tooltip, CircularProgress } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../api/supabaseClient';
import { styled } from '@mui/material/styles';
import { FaStar, FaTrophy } from "react-icons/fa";
import CreateLeaderboardButton from './CreateLeaderBoardButton';
import { useMisc } from '../../contexts/MiscContext';
import { GoldCoin } from '../Coins';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

// Updated TopPlayerCard with darker metallic colors for backgrounds
const TopPlayerCard = styled(Paper)<{ position: number }>(({ position }) => ({
    padding: '24px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
    borderRadius: '16px',
    backgroundColor: position === 1
        ? '#B8860B' // Darker gold/goldenrod for 1st
        : position === 2
            ? '#5A5A5A' // Darker silver/gray for 2nd
            : '#8C5E37', // Darker bronze for 3rd
    color: '#fff',
    boxShadow: position === 1
        ? '0 8px 24px rgba(218, 165, 32, 0.25), inset 0 0 60px rgba(218, 165, 32, 0.15)' // Gold glow
        : position === 2
            ? '0 8px 24px rgba(150, 150, 150, 0.25), inset 0 0 60px rgba(150, 150, 150, 0.15)' // Silver glow
            : '0 8px 24px rgba(176, 104, 47, 0.25), inset 0 0 60px rgba(176, 104, 47, 0.15)', // Bronze glow
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    border: position === 1
        ? '2px solid rgba(218, 165, 32, 0.7)' // Gold border
        : position === 2
            ? '2px solid rgba(169, 169, 169, 0.7)' // Silver border
            : '2px solid rgba(176, 104, 47, 0.7)', // Bronze border
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: position === 1
            ? '0 12px 28px rgba(218, 165, 32, 0.3), inset 0 0 70px rgba(218, 165, 32, 0.2)' // Enhanced gold glow
            : position === 2
                ? '0 12px 28px rgba(150, 150, 150, 0.3), inset 0 0 70px rgba(150, 150, 150, 0.2)' // Enhanced silver glow
                : '0 12px 28px rgba(176, 104, 47, 0.3), inset 0 0 70px rgba(176, 104, 47, 0.2)', // Enhanced bronze glow
    },
}));

const PlayerCard = styled(Paper)<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
    padding: '16px 24px',
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '12px',
    backgroundColor: isCurrentUser ? 'rgba(47, 120, 70, 0.35)' : 'rgba(35, 35, 40, 0.95)',
    color: '#f0f0f0', // Slightly off-white for better readability
    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    '&:hover': {
        transform: 'translateY(-3px)',
        boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
        backgroundColor: isCurrentUser ? 'rgba(47, 120, 70, 0.45)' : 'rgba(40, 40, 45, 0.95)',
    },
}));

const getPositionColor = (position: number): string => {
    const colors: Record<number, string> = {
        1: '#DAA520', // Darker gold
        2: '#A9A9A9', // Darker silver 
        3: '#B0682F', // Darker bronze
        4: '#2F7846', // Darker teal
        5: '#3B5998', // Darker blue
    };

    return colors[position] || '#555555';
};

const PositionIndicator: React.FC<{ position: number, children: React.ReactNode }> = ({ position, children }) => {
    return (
        <Box sx={{
            width: 42,
            height: 42,
            borderRadius: '50%',
            backgroundColor: getPositionColor(position),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#f0f0f0',
            fontWeight: 'bold',
            fontSize: '18px',
            mr: 2,
            boxShadow: '0 2px 8px rgba(0,0,0,0.3)',
            textShadow: '0 1px 2px rgba(0,0,0,0.5)'
        }}>
            {children}
        </Box>
    );
};

const PlaceText = styled(Typography)({
    fontFamily: '"Press Start 2P", "Roboto Mono", monospace',
    textShadow: '0 2px 4px rgba(0,0,0,0.7)', // Stronger shadow for better contrast
    fontWeight: 'bold',
    letterSpacing: '1px',
    marginBottom: '16px',
    color: '#f0f0f0' // Slightly off-white for better readability
});

const WagerChip = styled(Box)({
    backgroundColor: 'rgba(30, 30, 35, 0.7)',
    padding: '6px 14px',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 2px 6px rgba(0,0,0,0.25)',
    color: '#f0f0f0'
});

const PrizeChip = styled(Box)({
    backgroundColor: 'rgba(35, 35, 40, 0.7)',
    padding: '8px 16px',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 2px 8px rgba(0,0,0,0.25)',
    color: '#f0f0f0'
});

const StyledAvatar = styled(Avatar)({
    width: 56,
    height: 56,
    boxShadow: '0 3px 6px rgba(0,0,0,0.3)',
    border: '3px solid rgba(255,255,255,0.3)',
});

const LeaderboardHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(25, 25, 30, 0.95)',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4, 5),
    marginBottom: theme.spacing(5),
    boxShadow: '0 4px 16px rgba(0,0,0,0.3), inset 0 0 30px rgba(0,0,0,0.3)',
    color: '#f0f0f0', // Slightly off-white for better readability
    border: '1px solid rgba(180, 140, 40, 0.2)'
}));

const Badge = styled('img')({
    marginLeft: '6px',
});

const NoLeaderboardContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(6),
    backgroundColor: 'rgba(30, 30, 35, 0.95)',
    borderRadius: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
    border: '1px solid rgba(180, 140, 40, 0.15)'
}));

const Leaderboard: React.FC = () => {
    const { session, user } = useAuth();
    const { referrer, leaderboard } = useMisc();
    const [userAvatars, setUserAvatars] = useState<Record<string, string>>({});
    const [userInfo, setUserInfo] = useState<Record<string, {
        earlyadopter?: boolean,
        level?: number
    }>>({});
    const [currentUserPosition, setCurrentUserPosition] = useState<number | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    // Calculate prize distribution
    const calculatePrize = (position: number, total: number): number => {
        if (!leaderboard?.prize_distribution) return 0;

        const places = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth"];
        const placeName = places[position];

        // Get percentage from the actual prize distribution or use default
        const percentage = leaderboard.prize_distribution[placeName]
            ? leaderboard.prize_distribution[placeName] / 100
            : 0;

        return parseFloat((total * percentage).toFixed(2));
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                setLoading(true);
                setError(null);

                // Exit early if no leaderboard or no participants
                if (!leaderboard?.has_active_leaderboard || !leaderboard?.participants?.length) {
                    setLoading(false);
                    return;
                }

                // Find current user's position if they're logged in
                if (session?.user) {
                    const userIndex = leaderboard.participants.findIndex(
                        (p: any) => p.name.toLowerCase() === user?.username?.toLowerCase()
                    );
                    if (userIndex !== -1) {
                        setCurrentUserPosition(userIndex);
                    }
                }

                // Fetch user info from chat_users
                if (leaderboard.participants.length > 0) {
                    const usernames = leaderboard.participants.map((p: any) => p.name);
                    const { data: chatUsers, error: chatUsersError } = await supabase
                        .from('chat_users')
                        .select('name, avatar_url, earlyadopter, level')
                        .in('name', usernames);

                    if (chatUsersError) throw chatUsersError;

                    const avatarMap: Record<string, string> = {};
                    const infoMap: Record<string, {
                        earlyadopter?: boolean,
                        level?: number
                    }> = {};

                    // Process user info
                    if (chatUsers) {
                        chatUsers.forEach((user: any) => {
                            avatarMap[user.name] = user.avatar_url ||
                                `https://ui-avatars.com/api/?name=${encodeURIComponent(user.name)}&background=random&color=fff&size=64`;

                            infoMap[user.name] = {
                                earlyadopter: user.earlyadopter,
                                level: user.level
                            };
                        });
                    }

                    // Fill in missing avatars with placeholders
                    leaderboard.participants.forEach((participant: any) => {
                        if (!avatarMap[participant.name]) {
                            avatarMap[participant.name] = `https://ui-avatars.com/api/?name=${encodeURIComponent(participant.name)}&background=random&color=fff&size=64`;
                        }

                        if (!infoMap[participant.name]) {
                            infoMap[participant.name] = {};
                        }
                    });

                    setUserAvatars(avatarMap);
                    setUserInfo(infoMap);
                }
            } catch (err: any) {
                console.error('Error fetching user data:', err);
                setError(err.message || 'Failed to load user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserInfo();
    }, [leaderboard, session, user]);

    // Trophy colors for leaderboard positions
    const trophyColors = [
        '#DAA520', // Gold - less bright
        '#A9A9A9', // Silver - less bright
        '#B0682F'  // Bronze - less bright
    ];

    const renderUserWithBadges = (name: string, position?: number) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledAvatar src={userAvatars[name]} alt={name} />
            <Box sx={{ ml: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1, textShadow: '0 1px 2px rgba(0,0,0,0.3)' }}>
                        {name}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* Early Adopter Badge */}
                        {userInfo[name]?.earlyadopter && (
                            <Tooltip
                                title="Early Adopter"
                                placement="top"
                                arrow
                            >
                                <span>
                                    <FaStar
                                        style={{
                                            color: '#efbf04',
                                            width: '18px',
                                            height: '18px',
                                            marginLeft: '6px',
                                            marginBottom: '6px',
                                            filter: 'drop-shadow(0 1px 2px rgba(0,0,0,0.3))'
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        )}

                        {/* Level Badge */}
                        {userInfo[name]?.level > 0 && (
                            <Tooltip
                                title={`Level ${userInfo[name].level}`}
                                placement="top"
                                arrow
                            >
                                <Badge
                                    src={`/newlevels/lvl${userInfo[name].level}.png`}
                                    alt={`Level ${userInfo[name].level}`}
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        marginBottom: '4px',
                                        filter: 'drop-shadow(0 1px 2px rgba(0,0,0,0.3))'
                                    }}
                                />
                            </Tooltip>
                        )}

                        {/* Leader Trophy (if not already showing in their position) */}
                        {position === undefined && Array.isArray(leaderboard.participants) && (
                            (() => {
                                const rank = leaderboard.participants.findIndex(p => p.name === name);
                                if (rank >= 0 && rank < 3) {
                                    return (
                                        <Tooltip
                                            title={`#${rank + 1} Leaderboard`}
                                            placement="top"
                                            arrow
                                        >
                                            <span>
                                                <FaTrophy
                                                    style={{
                                                        color: trophyColors[rank],
                                                        width: '18px',
                                                        height: '18px',
                                                        marginLeft: '6px',
                                                        marginBottom: '4px',
                                                        filter: 'drop-shadow(0 1px 2px rgba(0,0,0,0.3))'
                                                    }}
                                                />
                                            </span>
                                        </Tooltip>
                                    );
                                }
                                return null;
                            })()
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Container className='min-h-screen' maxWidth="lg" sx={{ py: 5 }}>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                    <CircularProgress sx={{ color: '#D4AF37', size: 60 }} />
                </Box>
            ) : error ? (
                <Paper sx={{ p: 4, bgcolor: 'rgba(25, 25, 25, 0.95)', borderRadius: 2, textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ color: '#ff6b6b', fontWeight: 'bold' }}>
                        Error loading leaderboard
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#ccc', mt: 2 }}>
                        {error}
                    </Typography>
                </Paper>
            ) : !leaderboard?.has_active_leaderboard ? (
                <NoLeaderboardContainer>
                    <EmojiEventsIcon sx={{ fontSize: 80, color: 'rgba(200, 150, 40, 0.6)', mb: 2 }} />

                    <Typography variant="h3" sx={{
                        fontWeight: 'bold',
                        color: '#f0f0f0',
                        fontFamily: '"Press Start 2P", "Roboto Mono", monospace',
                        fontSize: '28px',
                        textShadow: '0 2px 4px rgba(0,0,0,0.7)',
                        letterSpacing: '1px'
                    }}>
                        No active leaderboard
                    </Typography>

                    <Typography variant="h6" sx={{
                        color: '#c0c0c0',
                        maxWidth: '600px',
                        margin: '0 auto',
                        fontWeight: 'bold'
                    }}>
                        {referrer ? `${referrer} does not have an active leaderboard at the moment.` : 'There is no active leaderboard at this time.'}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, color: '#888' }}>
                        <CalendarTodayIcon />
                        <Typography variant="body1">
                            Check back later for new leaderboard competitions!
                        </Typography>
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <CreateLeaderboardButton />
                    </Box>
                </NoLeaderboardContainer>
            ) : (
                <>
                    <LeaderboardHeader>
                        <Box>
                            <Typography variant="h6" sx={{ opacity: 0.9, fontSize: '18px', mb: 1, fontFamily: 'monospace', letterSpacing: 1, color: '#c0c0c0' }}>
                                {leaderboard.start_time && leaderboard.end_time ? (
                                    `${new Date(leaderboard.start_time).toLocaleDateString()} - ${new Date(leaderboard.end_time).toLocaleDateString()}`
                                ) : 'Dates not specified'}
                            </Typography>
                            <Typography variant="h3" sx={{ fontWeight: 'bold', textShadow: '0 2px 4px rgba(0,0,0,0.7)', letterSpacing: 1 }}>
                                {leaderboard.title}
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right', display: 'flex', alignItems: 'center', background: 'rgba(25,25,30,0.7)', borderRadius: '16px', p: 2 }}>
                            <Typography variant="subtitle1" sx={{ opacity: 0.9, mr: 1, fontSize: '24px', fontWeight: 'bold', color: '#c0c0c0' }}>
                                Prize Pool:
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <GoldCoin style={{ width: '24px', height: '24px' }} />
                                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#05f783', textShadow: '0 2px 4px rgba(0,0,0,0.7)' }}>
                                    {leaderboard.prize_pool?.toFixed(2)}
                                </Typography>
                            </Box>
                        </Box>
                    </LeaderboardHeader>

                    {/* Top 3 Players */}
                    {leaderboard.participants?.length > 0 && (
                        <Grid container spacing={4} sx={{ mb: 5 }}>
                            {/* 2nd Place */}
                            <Grid item xs={12} md={4}>
                                {leaderboard.participants.length > 1 ? (
                                    <TopPlayerCard position={2} elevation={3}>
                                        <PlaceText variant="h4">
                                            2nd PLACE
                                        </PlaceText>
                                        {renderUserWithBadges(leaderboard.participants[1].name, 1)}
                                        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1.5 }}>
                                            <WagerChip>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                    Wagered:
                                                </Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, gap: 0.5 }}>
                                                    <GoldCoin style={{ width: '20px', height: '20px' }} />
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#DAA520' }}>
                                                        {leaderboard.participants[1]?.lbwager.toFixed(2)}
                                                    </Typography>

                                                </Box>
                                            </WagerChip>
                                            <PrizeChip sx={{ mt: 1 }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, color: '#05f783' }}>
                                                    <GoldCoin style={{ width: '20px', height: '20px' }} />
                                                    {calculatePrize(1, leaderboard.prize_pool).toFixed(2)}
                                                </Typography>
                                            </PrizeChip>
                                        </Box>
                                    </TopPlayerCard>
                                ) : (
                                    <TopPlayerCard position={2} elevation={3} sx={{ opacity: 0.6 }}>
                                        <PlaceText variant="h4">
                                            2nd PLACE
                                        </PlaceText>
                                        <Typography variant="h6" sx={{ color: 'rgba(255,255,255,0.8)', textShadow: '0 1px 2px rgba(0,0,0,0.3)' }}>
                                            Waiting for players...
                                        </Typography>
                                    </TopPlayerCard>
                                )}
                            </Grid>

                            {/* 1st Place */}
                            <Grid item xs={12} md={4}>
                                {leaderboard.participants.length > 0 ? (
                                    <TopPlayerCard position={1} elevation={3} sx={{
                                        transform: 'scale(1.05)',
                                        zIndex: 2,
                                        height: '110%'
                                    }}>
                                        <PlaceText variant="h3">
                                            1st PLACE
                                        </PlaceText>
                                        {renderUserWithBadges(leaderboard.participants[0].name, 0)}
                                        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1.5 }}>
                                            <WagerChip>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                    Wagered:
                                                </Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, gap: 0.5 }}>
                                                    <GoldCoin style={{ width: '20px', height: '20px' }} />
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#DAA520' }}>
                                                        {leaderboard.participants[0]?.lbwager.toFixed(2)}
                                                    </Typography>
                                                </Box>
                                            </WagerChip>
                                            <PrizeChip sx={{ mt: 1 }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, color: '#05f783' }}>
                                                    <GoldCoin style={{ width: '20px', height: '20px' }} />
                                                    {calculatePrize(0, leaderboard.prize_pool).toFixed(2)}
                                                </Typography>
                                            </PrizeChip>
                                        </Box>
                                        {leaderboard.end_time && (
                                            <Typography variant="body2" sx={{ mt: 3, opacity: 0.9, fontWeight: 'bold', fontFamily: 'monospace' }}>
                                                Ends in {formatTimeRemaining(new Date(leaderboard.end_time))}
                                            </Typography>
                                        )}
                                    </TopPlayerCard>
                                ) : (
                                    <TopPlayerCard position={1} elevation={3} sx={{
                                        transform: 'scale(1.05)',
                                        zIndex: 2,
                                        height: '110%',
                                        opacity: 0.6
                                    }}>
                                        <PlaceText variant="h3">
                                            1st PLACE
                                        </PlaceText>
                                        <Typography variant="h6" sx={{ color: 'rgba(255,255,255,0.8)', textShadow: '0 1px 2px rgba(0,0,0,0.3)' }}>
                                            Waiting for players...
                                        </Typography>
                                    </TopPlayerCard>
                                )}
                            </Grid>

                            {/* 3rd Place */}
                            <Grid item xs={12} md={4}>
                                {leaderboard.participants.length > 2 ? (
                                    <TopPlayerCard position={3} elevation={3}>
                                        <PlaceText variant="h4">
                                            3rd PLACE
                                        </PlaceText>
                                        {renderUserWithBadges(leaderboard.participants[2].name, 2)}
                                        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1.5 }}>
                                            <WagerChip>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                    Wagered:
                                                </Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, gap: 0.5 }}>
                                                    <GoldCoin style={{ width: '20px', height: '20px' }} />
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#DAA520' }}>
                                                        {leaderboard.participants[2]?.lbwager.toFixed(2)}
                                                    </Typography>
                                                </Box>
                                            </WagerChip>
                                            <PrizeChip sx={{ mt: 1 }}>
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1, color: '#05f783' }}>
                                                    <GoldCoin style={{ width: '20px', height: '20px' }} />
                                                    {calculatePrize(2, leaderboard.prize_pool).toFixed(2)}
                                                </Typography>
                                            </PrizeChip>
                                        </Box>
                                    </TopPlayerCard>
                                ) : (
                                    <TopPlayerCard position={3} elevation={3} sx={{ opacity: 0.6 }}>
                                        <PlaceText variant="h4">
                                            3rd PLACE
                                        </PlaceText>
                                        <Typography variant="h6" sx={{ color: 'rgba(255,255,255,0.8)', textShadow: '0 1px 2px rgba(0,0,0,0.3)' }}>
                                            Waiting for players...
                                        </Typography>
                                    </TopPlayerCard>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {/* Current user position */}
                    {currentUserPosition !== null && session?.user && (
                        <Box sx={{ mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Paper sx={{ p: 3, bgcolor: 'rgba(25, 25, 25, 0.95)', borderRadius: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="h6" sx={{ color: '#4CAF50', fontWeight: 'bold' }}>
                                            Your Position: #{currentUserPosition + 1}
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Paper sx={{ p: 3, bgcolor: 'rgba(25, 25, 25, 0.95)', borderRadius: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                                        <Typography variant="h6" sx={{ color: '#FFC107', fontWeight: 'bold' }}>
                                            Your Wagered:
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <GoldCoin style={{ width: '24px', height: '24px' }} />
                                            <Typography variant="h6" sx={{ color: '#FFC107', fontWeight: 'bold' }}>
                                                {leaderboard.participants[currentUserPosition]?.lbwager.toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {/* Leaderboard Table Header */}
                    <Paper sx={{
                        p: 3,
                        mb: 3,
                        mt: 12,
                        display: 'flex',
                        justifyContent: 'space-between',
                        bgcolor: 'rgba(0, 0, 0, 0.4)',
                        color: '#ddd',
                        borderRadius: 2,
                        boxShadow: '0 4px 12px rgba(0,0,0,0.2)'
                    }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', flex: 2, textShadow: '0 1px 2px rgba(0,0,0,0.3)' }}>
                            POSITION & PLAYER
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', flex: 1, textAlign: 'center', textShadow: '0 1px 2px rgba(0,0,0,0.3)' }}>
                            WAGERED
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', flex: 1, textAlign: 'right', textShadow: '0 1px 2px rgba(0,0,0,0.3)' }}>
                            PRIZE
                        </Typography>
                    </Paper>

                    {/* Remaining Players (4th and beyond) */}
                    {leaderboard.participants?.length > 3 ? (
                        leaderboard.participants.slice(3).map((player, index) => {
                            const actualPosition = index + 4; // Starting from position 4 (4th place)
                            const isCurrentUser = currentUserPosition === actualPosition - 1;

                            return (
                                <PlayerCard key={index} isCurrentUser={isCurrentUser}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 2 }}>
                                        <PositionIndicator position={actualPosition}>
                                            {actualPosition}
                                        </PositionIndicator>
                                        {renderUserWithBadges(player.name)}
                                    </Box>

                                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                        <GoldCoin style={{ width: '22px', height: '22px' }} />
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                            {player.lbwager.toFixed(2)}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                        <PrizeChip>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <GoldCoin style={{ width: '20px', height: '20px' }} />
                                                {calculatePrize(actualPosition - 1, leaderboard.prize_pool).toFixed(2)}
                                            </Typography>
                                        </PrizeChip>
                                    </Box>
                                </PlayerCard>
                            );
                        })
                    ) : (
                        <Paper sx={{ p: 4, bgcolor: 'rgba(25, 25, 25, 0.8)', borderRadius: 2, textAlign: 'center' }}>
                            <Typography variant="h6" sx={{ color: '#aaa' }}>
                                Not enough participants yet to fill all positions
                            </Typography>
                        </Paper>
                    )}
                    <CreateLeaderboardButton />
                </>
            )}
        </Container>
    );
};

// Helper function to format time remaining
const formatTimeRemaining = (endDate: Date): string => {
    const now = new Date();
    const diff = endDate.getTime() - now.getTime();

    if (diff <= 0) return 'Ended';

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

export default Leaderboard;